import React, { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  ChartOptions,
  registerables,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
import { STATUS_CODE, openNotificationWithIcon } from "../../../utils";
import { NotAccessibleFallback } from "../../../components";
import { COPILOTS_NAME } from "../../../utils";
import { Moment } from "moment";
import moment from "moment";
import { Select } from "antd";
const { Option } = Select;

interface ProcessingTrendProps {
  fromDate: Moment;
  toDate: Moment;
  data: ChartStatsData[];
  copilotName?: string;
}

const getProcessLabel = (copilotName?: string) => {
  switch (copilotName) {
    case COPILOTS_NAME.CLAIMS:
      return "No. of search";
    case COPILOTS_NAME.CCA:
    case COPILOTS_NAME.ANG_INSIGHTS:
    case COPILOTS_NAME.UM:
      return "No. of requests processed";
    default:
      return "No. of documents processed";
  }
};

export const ProcessingTrend = ({ fromDate, toDate, data, copilotName }: ProcessingTrendProps) => {
  const [loading, setLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [timeGranularity, setTimeGranularity] = useState("days"); // Default granularity
  const [extractionData, setExtractionData] = useState<{ month: string; value: number }[]>([]);

  const processProcessingData = (granularity: string) => {
    const result: { month: string; value: number }[] = [];
    const dataMap = new Map<string, number>();

    data.forEach(({ date, processed }) => {
      const formattedDate = moment(date).format(
        granularity === "weeks"
          ? "YYYY-WW"
          : granularity === "months"
          ? "YYYY-MM"
          : granularity === "days"
          ? "YYYY-MM-DD"
          : "YYYY"
      );

      if (dataMap.has(formattedDate)) {
        dataMap.set(formattedDate, dataMap.get(formattedDate)! + processed);
      } else {
        dataMap.set(formattedDate, processed);
      }
    });

    dataMap.forEach((value, month) => {
      result.push({ month, value });
    });

    return result;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const processedData = processProcessingData(timeGranularity);
        setExtractionData(processedData);
      } catch (err: any) {
        if (err.response && err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          // openNotificationWithIcon(
          //   "",
          //   err.response?.data?.message || "Error fetching data",
          //   "error"
          // );
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timeGranularity, data]);

  const chartData = useMemo(() => {
    return {
      labels: extractionData.map((item) => item.month),
      datasets: [
        {
          label: getProcessLabel(copilotName),
          data: extractionData.map((item) => item.value),
          backgroundColor: (context: any) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, "#98DAFF");
            gradient.addColorStop(1, "#407CCA");
            return gradient;
          },
          borderColor: (context: any) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, "rgb(152 218 255 / 50%)");
            gradient.addColorStop(1, "rgb(64 124 202)");
            return gradient;
          },
          borderWidth: 1,
          borderRadius: 5,
        },
      ],
    };
  }, [extractionData, copilotName]);

  const options = useMemo(() => {
    return {
      scales: {
        x: {
          type: "category",
          stacked: true,
          grid: {
            drawOnChartArea: false,
          },
        },
        y: {
          stacked: true,
          beginAtZero: true,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      height: 400,
      plugins: {
        legend: {
          position: "bottom" as const,
        },
      },
    } as ChartOptions<"bar">;
  }, []);

  const onTimeGranularityChange = (granularity: string) => {
    setTimeGranularity(granularity);
  };

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }

  if (data.length === 0) {
    return <p>No data available</p>;
  }

  const renderDescription = () => {
    if (copilotName === COPILOTS_NAME.CLAIMS) {
      return "Total number of search initiated by days, week & months";
    }
  
    if (copilotName && [COPILOTS_NAME.CCA, COPILOTS_NAME.ANG_INSIGHTS, COPILOTS_NAME.UM].includes(copilotName)) {
      return "Total number of requests processed by days, week & months";
    }
  
    return "Total number of documents processed by days, week & months";
  };
  

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h4 className="summary-title">{copilotName === COPILOTS_NAME.CLAIMS ? "Search Trend" : "Processing Trend"}</h4>
          <p>
            {renderDescription()}
          </p>
        </div>
        <div style={{ paddingBottom: "20px" }}>
          <Select
            defaultValue={timeGranularity}
            style={{ width: 100, height: 40 }}
            onChange={onTimeGranularityChange}
          >
            <Option value="days">Days</Option>
            <Option value="weeks">Weeks</Option>
            <Option value="months">Months</Option>
            <Option value="years">Years</Option>
          </Select>
        </div>
      </div>
      <div style={{ height: "375px" }}>
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};
