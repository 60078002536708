import { apiClient } from "./apiClient";
import qs from "qs";


const getSearchesData = async (reqObj: IGetDocumentsRequest) => {
    const { searchTerm, searchDocumentUUID: documentUUID, ...rest } = reqObj;
    const queryObj: Record<string, any> = { ...rest, documentUUID };
    const query = qs.stringify(queryObj, { skipNulls: true });
    const res = await apiClient.get(`/claims/search-analysis?${query}`);
    return res;
  };

export const searchAnalysisServices = {
    getSearchesData
}