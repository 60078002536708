import { Button, Table } from "antd";
import React from "react";
import { evidenceViewerService } from "../../../../api";

interface PcpSummaryProps {
  data: PcpData;
  fileId: string;
}

export interface PcpData {
  dateOfBirth: string;
  memberName: string;
  memberID: string;
  clinicName: string;
  pcpName: string;
  memberGuardianFullName: string;
  pcpAddress_City: string;
  pcpAddress_ZIP: string;
  signatureAvailable: string;
  todayDate: string;
  transaction_id: string;
}

const columns = [
  {
    title: "Key",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "Values",
    dataIndex: "value",
    key: "value",
  },
];

const PcpSummaryComponent: React.FC<PcpSummaryProps> = ({ data, fileId }) => {
  const keyValueArray = Object.keys(data).map((key) => {
    const value = data[key as keyof PcpData];
    return {
      key,
      value: value === "[]" ? undefined : value, 
    };
  });
  

  const handleDownloadSummary = async () => {
    const csvString = await evidenceViewerService.downloadPCPSummary(fileId);
    const blob = new Blob([csvString], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "pcp_summary.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Table
        className="summaryTable"
        dataSource={keyValueArray}
        columns={columns}
        rowKey="key"
        pagination={false}
      />
      <Button className="outline" onClick={handleDownloadSummary}>
        Download Summary
      </Button>
    </div>
  );
};

export default PcpSummaryComponent;
