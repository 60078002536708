import { notification } from "antd";

export type NotificationType = "success" | "info" | "warning" | "error";

export const openNotificationWithIcon = (
  title: string,
  description: string,
  type: NotificationType
) => {
  description && notification[type]({
    message: title,
    description,
    duration: 6
  });
};
