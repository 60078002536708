import moment from "moment";

export const getCurrentDateTime = () =>
  new Date().toLocaleDateString() + ", " + new Date().toLocaleTimeString();

export const getDate = (date: Date) => new Date(date).toLocaleDateString("en-ca");

export const getTime = (date: Date) =>
  `${
    date
      .toLocaleDateString("en-us", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      })
      .split(",")[1]
  }`;

  export const getTimeInSeconds = (date: Date) => `${
    date
      .toLocaleDateString("en-us", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      })
      .split(",")[1]
  }`

export const getLocalDateTimeStringFromISO = (datetime: string) => {
  if (!datetime) return "-";
  const date = new Date(datetime);
  return `${getDate(date)} ${getTime(date)}`;
};

export const getLocalDateTimeString = (datetime: string) => {
  if (!datetime) return "-";
    return moment(datetime).format("YYYY-MM-DD hh:mm:ss A"); 
};
