import React from "react";
import { EvidencePageResponseInfo, Form, PegaPageDataSummary } from "../../../api";
import ReactJson from "react-json-view";

interface Props {
  pageInfo: any;
  collapsed?: boolean
}

export const EvidenceContentJSON = ({ pageInfo, collapsed = false }: Props) => {
  return (
    <div className="e-text">
      {/* <h2>{pageInfo.heading}</h2> */}
      <div className="e-paragraph ">
        <ReactJson src={JSON.parse(JSON.stringify(pageInfo))} enableClipboard collapsed={collapsed}/>
      </div>
    </div>
  );
};
