import { Dispatch } from "redux";
import { RootState, closeModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { connect } from "react-redux";
import FileUploadModal from "./FileUploadModal";
import { ClaimsService } from "../../../api/claims";

type StateProps = {
    visibility: boolean;
    documentId: number;
};

type DispatchProps = {
  handleClose: () => void;
  handleUpload?: (filePath: string, cleanExistingData: boolean, file: any) => Promise<any>; 
};

export type FileUploadModalProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.UPLOAD_CSV_FILE],
  documentId: state.document.documentId,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.UPLOAD_CSV_FILE));
  },
  handleUpload: async (filePath: string, cleanExistingData: boolean, file: any) => {
    try {
      const uploadUrl: any = await ClaimsService.getUploadSignedUrl(filePath);
      await ClaimsService.processUploadedFile(uploadUrl.url, filePath, file);
      const res = await ClaimsService.importCSVFileFromBlob(filePath, cleanExistingData);
      return res;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadModal);
