import { Dispatch } from "redux";
import { RootState, closeModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { connect } from "react-redux";
import UploadConfirmModal from "./UploadConfirmation";
import { ClaimsService } from "../../../api/claims";

type StateProps = {
  visibility: boolean;
  documentId: number;
};

type DispatchProps = {
  handleClose: () => void;
  handleUpload?: (cleanExistingData: boolean) => Promise<any>;
};

export type FileUploadModalProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: state.ui.modal[MODAL_TYPE.IMPORT_CSV_FILE],
  documentId: state.document.documentId,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.IMPORT_CSV_FILE));
  },
  handleUpload: async ( cleanExistingData: boolean) => {
    try {
      const res = await ClaimsService.importCSVFile(cleanExistingData);
      return res;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error; 
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadConfirmModal);
