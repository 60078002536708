import moment from "moment";
import { COPILOTS_NAME, getCopilotParam } from "../utils";
import { apiClient } from "./apiClient";
import qs from "qs";

const getData = async (copilotName?: string) => {
  const res = await apiClient.get<ITokenUsageResponse>(
    `/token-billing?fromDate=${moment()
      .startOf("month")
      .format("YYYY-MM-DD")}&toDate=${moment()}&copilot=${
      copilotName === COPILOTS_NAME.HEDIS_INSIGHTS
        ? "hedis"
        : copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH
        ? "pega"
        : copilotName === COPILOTS_NAME.CAREGPT_CHATBOT
        ? "mosaic"
        : "all"
    }`
  );
  return res;
};

const getTokens = async (reqObj: IGetDocumentsRequestToken) => {
  const { fromDate,toDate} = reqObj;
  let copilot = "";
  switch (reqObj.copilot) {
    case COPILOTS_NAME.CCA:
      copilot = "CCA";
      break;
    case COPILOTS_NAME.ANG_INSIGHTS:
      copilot = "ANG";
      break;
    case COPILOTS_NAME.UM:
      copilot = "UM";
      break;
    default:
      copilot = "";
      break;
  }
  const query = qs.stringify(
    {
      fromDate,
      toDate,
      copilot,
    },
    { addQueryPrefix: true, skipNulls: true }
  );

  const url = `/requests/token${query}`;
  const res = await apiClient.get(url);
  return res;
};

const getPcpTokens = async (reqObj: IGetDocumentsRequestToken) => {
  const { fromDate,toDate} = reqObj;

  const res = await apiClient.get('/pcp/metrics', {
    params: {
      fromDate: fromDate,
      toDate: toDate,
    },
  }); 
  return res;
}

const getTokenMatrices = async (reqObj: IGetDocumentsRequestToken) => {
  const { fromDate, toDate, copilot} = reqObj;

  const copilotName = getCopilotParam(copilot)

  const res = await apiClient.get('/token-billing/utilization', {
    params: {
      fromDate: fromDate,
      toDate: toDate,
      copilot : copilotName
    },
  }); 
  return res;}

export const tokenBillingService = { getData,getTokens, getPcpTokens, getTokenMatrices };
