import axios from "axios";
import { apiClient } from "./apiClient";
import qs from "qs";

export interface GetUploadSignedUrl {
    data: {
      signedUrl: string;
      accessToken?: string;
    };
    status: string;
  }

const getUploadSignedUrl = async (path: string) => {
    const res = await apiClient.get<GetUploadSignedUrl>(
      `claims/storage/blobSignedUrl/upload?path=${path}`
    );
    return res.data;
  };

  const processUploadedFile = async(url: string, filename: string, file: any) => {
    const fileData = file.originFileObj || file; 
    const res = await axios({
      method: "put",
      url,
      data: fileData,
      headers: {
        "Content-Type": fileData.type,
        "x-ms-blob-type": "BlockBlob",
      },
    });
    return res
  }

  const importCSVFile = async ( cleanRep:boolean ) => {
    const res = await apiClient.post(
      `claims/details/claims-data/import?cleanRepo=${cleanRep}`
    );
    return res.data;
  }

const importCSVFileFromBlob = async ( fileName:string, cleanRep:boolean ) => {
  const res = await apiClient.post(
    `claims/storage/claims-data/import`, {
      fileName, cleanRepo: cleanRep
    }
  );
  return res;
}

const getClaimsData = async ( limit: number, offset: number ) => {
  const res = await apiClient.get(
    `claims/details/claims-data`, {
      params:{limit, offset}
    }
  );
  return res;
}

export const ClaimsService = {
    getUploadSignedUrl,
    importCSVFile,
    getClaimsData,
    processUploadedFile,
    importCSVFileFromBlob
}