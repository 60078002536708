import React, { useState } from "react";
import { Modal, Checkbox, Button, message } from "antd";
import { CloseOutlined, ImportOutlined } from "@ant-design/icons";
import "../modal.scss";
import { Tag } from "antd/lib";
import { openNotificationWithIcon } from "../../../utils";
import { setIsFileImported } from "../../../store";
import { useDispatch } from "react-redux";

interface FileUploadModalProps {
  visibility: boolean;
  documentId?: number;
  handleClose: () => void;
  handleUpload?: (cleanExistingData: boolean) => Promise<void>;
}

const UploadConfirmModal: React.FC<FileUploadModalProps> = ({
  visibility,
  handleClose,
  handleUpload,
}) => {
  const [cleanExistingData, setCleanExistingData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleModalClose = () => {
    handleClose();
  };

  const handleConfirmUpload = async () => {
    if (handleUpload) {
      setIsLoading(true);
      try {
        const res:any = await handleUpload(cleanExistingData);
        const {  processedRecords } = res;

        const notificationMessage = `Successfully inserted ${processedRecords} entries.`;
        openNotificationWithIcon("Import Successful",notificationMessage, "success");
        dispatch(setIsFileImported(true));
        handleModalClose();
      } catch (error: any) {
        console.log(error)
        openNotificationWithIcon("Failed to import file", error.response.data.message, "error");
      } finally {
        setIsLoading(false);
        
      }
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      title="CSV File Upload"
      open={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleModalClose} />}
    >


        <h1 className="m-b">Import CSV File</h1>
        

      <div className="file-upload-content flex flex-column gp">
        {/* Clean Repo Checkbox */}
        <div className="info-container">
          <Checkbox
            className="m-b"
            checked={cleanExistingData}
            onChange={(e) => setCleanExistingData(e.target.checked)}
          >
            Clean existing data before uploading
          </Checkbox>
          <div className="flex">

          <Tag bordered={false} color="warning" className="flex tag-container">
            <strong>Note:</strong>&nbsp; 
            Enabling this option will <strong>&nbsp;delete all existing entries</strong>&nbsp;from the table before importing the new CSV file. 
            If unchecked, the new data will be&nbsp;<strong>appended</strong>&nbsp; 
            to the existing records.
         </Tag>

          </div>
        </div>

        <div className="flex jcc gp">
          <Button
            type="primary"
            className="outline"
            onClick={handleModalClose}
            disabled={isLoading}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            className="fill"
            disabled={isLoading}
            loading={isLoading}
            onClick={handleConfirmUpload}
          >
            {isLoading ? "Uploading..." : "Confirm Import"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadConfirmModal;
