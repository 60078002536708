export const tokenUsagePega = {
  data: {
    message: "OK",
    data: [
      {
        date: "May 2023",
        token_count: [
          {
            service_name: "Pega UM",
            token_count: 974,
          },
        ],
      },
      {
        date: "June 2023",
        token_count: [
          {
            service_name: "Pega UM",
            token_count: 2377,
          },
        ],
      },
      {
        date: "July 2023",
        token_count: [],
      },
      {
        date: "August 2023",
        token_count: [
          {
            service_name: "Pega UM",
            token_count: 1925,
          },
        ],
      },
      {
        date: "September 2023",
        token_count: [
          {
            service_name: "Pega UM",
            token_count: 4574,
          },
        ],
      },
    ],
    transaction_id: "49501aac-84ac-45dc-9c0f-297c5b3027f4",
  },
};

export const tokenUsageHedis = {
  data: {
    message: "OK",
    data: [
      {
        date: "May 2023",
        token_count: [
          {
            service_name: "Hedis",
            token_count: 4931,
          },
        ],
      },
      {
        date: "June 2023",
        token_count: [
          {
            service_name: "Hedis",
            token_count: 2639,
          },
        ],
      },
      {
        date: "July 2023",
        token_count: [
          {
            service_name: "Hedis",
            token_count: 9212,
          },
        ],
      },
      {
        date: "August 2023",
        token_count: [
          {
            service_name: "Hedis",
            token_count: 5143,
          },
        ],
      },
      {
        date: "September 2023",
        token_count: [
          {
            service_name: "Hedis",
            token_count: 485,
          },
        ],
      },
    ],
    transaction_id: "49501aac-84ac-45dc-9c0f-297c5b3027f4",
  },
};
export const tokenUsagePaymentIntegrity = {
  data: {
    message: "OK",
    data: [
      {
        date: "May 2023",
        token_count: [
          {
            service_name: "Payment Integrity",
            token_count: 2628,
          },
        ],
      },
      {
        date: "June 2023",
        token_count: [],
      },
      {
        date: "July 2023",
        token_count: [],
      },
      {
        date: "August 2023",
        token_count: [
          {
            service_name: "Payment Integrity",
            token_count: 4866,
          },
        ],
      },
      {
        date: "September 2023",
        token_count: [
          {
            service_name: "Payment Integrity",
            token_count: 13203,
          },
        ],
      },
    ],
    transaction_id: "49501aac-84ac-45dc-9c0f-297c5b3027f4",
  },
};
export const ProcessingReportData = [
  {
    Copilot: "HEDIS",
    DocumentUUID: "952cbbb1-64ca-4066-ab44-13f376dcbfa6",
    Process: "SUCCEEDED",
    "Start Time": "2023-11-22 18:01:43.6350000 +00:00",
    "End Time": "2023-11-22 18:01:43.6350000 +00:00",
    DocumentProcessStatuses: [
      {
        Id: 17465,
        DocumentProcessId: 783,
        Status: "File Uploaded",
        createdAt: "2023-11-22T14:15:17.258Z",
        updatedAt: "2023-11-22T14:15:17.258Z",
        message: "This file has been successfully uploaded.",
      },
      {
        Id: 17466,
        DocumentProcessId: 783,
        Status: "Loading File",
        createdAt: "2023-11-22T14:15:17.354Z",
        updatedAt: "2023-11-22T14:15:18.449Z",
        message: "",
      },
      {
        Id: 17467,
        DocumentProcessId: 783,
        Status: "Extracting Pages",
        createdAt: "2023-11-22T14:15:18.539Z",
        updatedAt: "2023-11-22T14:15:23.738Z",
        message: "",
      },
      {
        Id: 17468,
        DocumentProcessId: 783,
        Status: "Processing Pages for Data extraction (OCR)",
        createdAt: "2023-11-22T14:15:23.768Z",
        updatedAt: "2023-11-22T14:18:12.689Z",
        message: "",
      },
      {
        Id: 17469,
        DocumentProcessId: 783,
        Status: "Clinical LLM Linking",
        createdAt: "2023-11-22T14:15:31.426Z",
        updatedAt: "2023-11-22T14:18:13.144Z",
        message: "",
      },
      {
        Id: 17470,
        DocumentProcessId: 783,
        Status: "ICD10 Mapping",
        createdAt: "2023-11-22T14:15:31.898Z",
        updatedAt: "2023-11-22T14:18:13.355Z",
        message: "",
      },
      {
        Id: 17471,
        DocumentProcessId: 783,
        Status: "Clinical Data Extraction Started",
        createdAt: "2023-11-22T14:18:12.743Z",
        updatedAt: "2023-11-22T14:18:13.240Z",
        message: "The information extraction process for this file has initiated.",
      },
      {
        Id: 17485,
        DocumentProcessId: 783,
        Status: "Extracting Hedis Insights",
        createdAt: "2023-11-22T16:08:22.370Z",
        updatedAt: "2023-11-22T18:01:17.678Z",
        message: "",
      },
      {
        Id: 17486,
        DocumentProcessId: 783,
        Status: "Summarization using Generative AI",
        createdAt: "2023-11-22T16:08:23.038Z",
        updatedAt: "2023-11-22T18:01:17.222Z",
        message: "",
      },
      {
        Id: 17502,
        DocumentProcessId: 783,
        Status: "SUCCEEDED",
        createdAt: "2023-11-22T18:01:43.635Z",
        updatedAt: "2023-11-22T18:01:43.635Z",
        message: "",
      },
    ],
  },
  {
    Copilot: "HEDIS",
    DocumentUUID: "1e7f893e-d5df-4f36-ba35-91154ff360fb",
    Process: "SUCCEEDED",
    "Start Time": "2023-11-22 17:42:46.1340000 +00:00",
    "End Time": "2023-11-22 17:42:46.1340000 +00:00",
    DocumentProcessStatuses: [
      {
        Id: 17458,
        DocumentProcessId: 782,
        Status: "File Uploaded",
        createdAt: "2023-11-22T14:13:11.930Z",
        updatedAt: "2023-11-22T14:13:11.930Z",
        message: "This file has been successfully uploaded.",
      },
      {
        Id: 17459,
        DocumentProcessId: 782,
        Status: "Loading File",
        createdAt: "2023-11-22T14:13:12.056Z",
        updatedAt: "2023-11-22T14:13:12.752Z",
        message: "",
      },
      {
        Id: 17460,
        DocumentProcessId: 782,
        Status: "Extracting Pages",
        createdAt: "2023-11-22T14:13:12.782Z",
        updatedAt: "2023-11-22T14:13:13.735Z",
        message: "",
      },
      {
        Id: 17461,
        DocumentProcessId: 782,
        Status: "Processing Pages for Data extraction (OCR)",
        createdAt: "2023-11-22T14:13:13.766Z",
        updatedAt: "2023-11-22T14:13:50.253Z",
        message: "",
      },
      {
        Id: 17462,
        DocumentProcessId: 782,
        Status: "Clinical LLM Linking",
        createdAt: "2023-11-22T14:13:21.121Z",
        updatedAt: "2023-11-22T14:13:50.797Z",
        message: "",
      },
      {
        Id: 17463,
        DocumentProcessId: 782,
        Status: "ICD10 Mapping",
        createdAt: "2023-11-22T14:13:21.442Z",
        updatedAt: "2023-11-22T14:13:51.003Z",
        message: "",
      },
      {
        Id: 17464,
        DocumentProcessId: 782,
        Status: "Clinical Data Extraction Started",
        createdAt: "2023-11-22T14:13:50.286Z",
        updatedAt: "2023-11-22T14:13:50.610Z",
        message: "The information extraction process for this file has initiated.",
      },
      {
        Id: 17479,
        DocumentProcessId: 782,
        Status: "Extracting Hedis Insights",
        createdAt: "2023-11-22T15:54:57.319Z",
        updatedAt: "2023-11-22T17:42:44.641Z",
        message: "",
      },
      {
        Id: 17480,
        DocumentProcessId: 782,
        Status: "Summarization using Generative AI",
        createdAt: "2023-11-22T15:54:57.881Z",
        updatedAt: "2023-11-22T17:42:44.118Z",
        message: "",
      },
      {
        Id: 17501,
        DocumentProcessId: 782,
        Status: "SUCCEEDED",
        createdAt: "2023-11-22T17:42:46.134Z",
        updatedAt: "2023-11-22T17:42:46.134Z",
        message: "",
      },
    ],
  },
  {
    Copilot: "HEDIS",
    DocumentUUID: "3adfc6f7-0319-4fd4-9cf6-059c644e4dc6",
    Process: "SUCCEEDED",
    "Start Time": "2023-11-22 17:03:30.9270000 +00:00",
    "End Time": "2023-11-22 17:03:30.9270000 +00:00",
    DocumentProcessStatuses: [
      {
        Id: 17451,
        DocumentProcessId: 781,
        Status: "File Uploaded",
        createdAt: "2023-11-22T14:00:03.122Z",
        updatedAt: "2023-11-22T14:00:03.122Z",
        message: "This file has been successfully uploaded.",
      },
      {
        Id: 17452,
        DocumentProcessId: 781,
        Status: "Loading File",
        createdAt: "2023-11-22T14:00:03.255Z",
        updatedAt: "2023-11-22T14:00:03.831Z",
        message: "",
      },
      {
        Id: 17453,
        DocumentProcessId: 781,
        Status: "Extracting Pages",
        createdAt: "2023-11-22T14:00:03.861Z",
        updatedAt: "2023-11-22T14:00:03.952Z",
        message: "",
      },
      {
        Id: 17454,
        DocumentProcessId: 781,
        Status: "Processing Pages for Data extraction (OCR)",
        createdAt: "2023-11-22T14:00:04.039Z",
        updatedAt: "2023-11-22T14:00:15.388Z",
        message: "",
      },
      {
        Id: 17455,
        DocumentProcessId: 781,
        Status: "Clinical LLM Linking",
        createdAt: "2023-11-22T14:00:15.337Z",
        updatedAt: "2023-11-22T14:00:16.619Z",
        message: "",
      },
      {
        Id: 17456,
        DocumentProcessId: 781,
        Status: "Clinical Data Extraction Started",
        createdAt: "2023-11-22T14:00:15.427Z",
        updatedAt: "2023-11-22T14:00:15.930Z",
        message: "The information extraction process for this file has initiated.",
      },
      {
        Id: 17457,
        DocumentProcessId: 781,
        Status: "ICD10 Mapping",
        createdAt: "2023-11-22T14:00:15.591Z",
        updatedAt: "2023-11-22T14:00:16.858Z",
        message: "",
      },
      {
        Id: 17489,
        DocumentProcessId: 781,
        Status: "Extracting Hedis Insights",
        createdAt: "2023-11-22T16:09:35.773Z",
        updatedAt: "2023-11-22T17:03:29.576Z",
        message: "",
      },
      {
        Id: 17490,
        DocumentProcessId: 781,
        Status: "Summarization using Generative AI",
        createdAt: "2023-11-22T16:09:36.480Z",
        updatedAt: "2023-11-22T17:03:29.154Z",
        message: "",
      },
      {
        Id: 17493,
        DocumentProcessId: 781,
        Status: "SUCCEEDED",
        createdAt: "2023-11-22T17:03:30.927Z",
        updatedAt: "2023-11-22T17:03:30.927Z",
        message: "",
      },
    ],
  },
];

export const documentsData = {
  message: "OK",
  data: [
    {
      Id: 783,
      DocumentUUID: "952cbbb1-64ca-4066-ab44-13f376dcbfa6",
      BucketName: null,
      FileName: "Test-Hedis/0003_COL-03.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T14:15:17.224Z",
      updatedAt: "2023-11-22T14:15:17.224Z",
      DocumentProcessStatuses: [
        {
          Id: 17465,
          DocumentProcessId: 783,
          Status: "File Uploaded",
          createdAt: "2023-11-22T14:15:17.258Z",
          updatedAt: "2023-11-22T14:15:17.258Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17466,
          DocumentProcessId: 783,
          Status: "Loading File",
          createdAt: "2023-11-22T14:15:17.354Z",
          updatedAt: "2023-11-22T14:15:18.449Z",
          message: "",
        },
        {
          Id: 17467,
          DocumentProcessId: 783,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T14:15:18.539Z",
          updatedAt: "2023-11-22T14:15:23.738Z",
          message: "",
        },
        {
          Id: 17468,
          DocumentProcessId: 783,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T14:15:23.768Z",
          updatedAt: "2023-11-22T14:18:12.689Z",
          message: "",
        },
        {
          Id: 17469,
          DocumentProcessId: 783,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T14:15:31.426Z",
          updatedAt: "2023-11-22T14:18:13.144Z",
          message: "",
        },
        {
          Id: 17470,
          DocumentProcessId: 783,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T14:15:31.898Z",
          updatedAt: "2023-11-22T14:18:13.355Z",
          message: "",
        },
        {
          Id: 17471,
          DocumentProcessId: 783,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T14:18:12.743Z",
          updatedAt: "2023-11-22T14:18:13.240Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17485,
          DocumentProcessId: 783,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T16:08:22.370Z",
          updatedAt: "2023-11-22T18:01:17.678Z",
          message: "",
        },
        {
          Id: 17486,
          DocumentProcessId: 783,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T16:08:23.038Z",
          updatedAt: "2023-11-22T18:01:17.222Z",
          message: "",
        },
        {
          Id: 17502,
          DocumentProcessId: 783,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T18:01:43.635Z",
          updatedAt: "2023-11-22T18:01:43.635Z",
          message: "",
        },
      ],
    },
    {
      Id: 782,
      DocumentUUID: "1e7f893e-d5df-4f36-ba35-91154ff360fb",
      BucketName: null,
      FileName: "Test-Hedis/0002_COL-02.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T14:13:11.906Z",
      updatedAt: "2023-11-22T14:13:11.906Z",
      DocumentProcessStatuses: [
        {
          Id: 17458,
          DocumentProcessId: 782,
          Status: "File Uploaded",
          createdAt: "2023-11-22T14:13:11.930Z",
          updatedAt: "2023-11-22T14:13:11.930Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17459,
          DocumentProcessId: 782,
          Status: "Loading File",
          createdAt: "2023-11-22T14:13:12.056Z",
          updatedAt: "2023-11-22T14:13:12.752Z",
          message: "",
        },
        {
          Id: 17460,
          DocumentProcessId: 782,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T14:13:12.782Z",
          updatedAt: "2023-11-22T14:13:13.735Z",
          message: "",
        },
        {
          Id: 17461,
          DocumentProcessId: 782,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T14:13:13.766Z",
          updatedAt: "2023-11-22T14:13:50.253Z",
          message: "",
        },
        {
          Id: 17462,
          DocumentProcessId: 782,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T14:13:21.121Z",
          updatedAt: "2023-11-22T14:13:50.797Z",
          message: "",
        },
        {
          Id: 17463,
          DocumentProcessId: 782,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T14:13:21.442Z",
          updatedAt: "2023-11-22T14:13:51.003Z",
          message: "",
        },
        {
          Id: 17464,
          DocumentProcessId: 782,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T14:13:50.286Z",
          updatedAt: "2023-11-22T14:13:50.610Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17479,
          DocumentProcessId: 782,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T15:54:57.319Z",
          updatedAt: "2023-11-22T17:42:44.641Z",
          message: "",
        },
        {
          Id: 17480,
          DocumentProcessId: 782,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T15:54:57.881Z",
          updatedAt: "2023-11-22T17:42:44.118Z",
          message: "",
        },
        {
          Id: 17501,
          DocumentProcessId: 782,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T17:42:46.134Z",
          updatedAt: "2023-11-22T17:42:46.134Z",
          message: "",
        },
      ],
    },
    {
      Id: 781,
      DocumentUUID: "3adfc6f7-0319-4fd4-9cf6-059c644e4dc6",
      BucketName: null,
      FileName: "Test-Hedis/0001_CT_001_COL-01.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T14:00:03.090Z",
      updatedAt: "2023-11-22T14:00:03.090Z",
      DocumentProcessStatuses: [
        {
          Id: 17451,
          DocumentProcessId: 781,
          Status: "File Uploaded",
          createdAt: "2023-11-22T14:00:03.122Z",
          updatedAt: "2023-11-22T14:00:03.122Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17452,
          DocumentProcessId: 781,
          Status: "Loading File",
          createdAt: "2023-11-22T14:00:03.255Z",
          updatedAt: "2023-11-22T14:00:03.831Z",
          message: "",
        },
        {
          Id: 17453,
          DocumentProcessId: 781,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T14:00:03.861Z",
          updatedAt: "2023-11-22T14:00:03.952Z",
          message: "",
        },
        {
          Id: 17454,
          DocumentProcessId: 781,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T14:00:04.039Z",
          updatedAt: "2023-11-22T14:00:15.388Z",
          message: "",
        },
        {
          Id: 17455,
          DocumentProcessId: 781,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T14:00:15.337Z",
          updatedAt: "2023-11-22T14:00:16.619Z",
          message: "",
        },
        {
          Id: 17456,
          DocumentProcessId: 781,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T14:00:15.427Z",
          updatedAt: "2023-11-22T14:00:15.930Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17457,
          DocumentProcessId: 781,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T14:00:15.591Z",
          updatedAt: "2023-11-22T14:00:16.858Z",
          message: "",
        },
        {
          Id: 17489,
          DocumentProcessId: 781,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T16:09:35.773Z",
          updatedAt: "2023-11-22T17:03:29.576Z",
          message: "",
        },
        {
          Id: 17490,
          DocumentProcessId: 781,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T16:09:36.480Z",
          updatedAt: "2023-11-22T17:03:29.154Z",
          message: "",
        },
        {
          Id: 17493,
          DocumentProcessId: 781,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T17:03:30.927Z",
          updatedAt: "2023-11-22T17:03:30.927Z",
          message: "",
        },
      ],
    },
    {
      Id: 780,
      DocumentUUID: "470e4ee8-f6c5-4117-9caa-6a3465a55a65",
      BucketName: null,
      FileName: "Test-Hedis/0001_COL-01.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T13:59:10.661Z",
      updatedAt: "2023-11-22T13:59:10.661Z",
      DocumentProcessStatuses: [
        {
          Id: 17444,
          DocumentProcessId: 780,
          Status: "File Uploaded",
          createdAt: "2023-11-22T13:59:10.697Z",
          updatedAt: "2023-11-22T13:59:10.697Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17445,
          DocumentProcessId: 780,
          Status: "Loading File",
          createdAt: "2023-11-22T13:59:10.849Z",
          updatedAt: "2023-11-22T13:59:11.750Z",
          message: "",
        },
        {
          Id: 17446,
          DocumentProcessId: 780,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T13:59:11.785Z",
          updatedAt: "2023-11-22T13:59:11.851Z",
          message: "",
        },
        {
          Id: 17447,
          DocumentProcessId: 780,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T13:59:11.941Z",
          updatedAt: "2023-11-22T13:59:23.053Z",
          message: "",
        },
        {
          Id: 17448,
          DocumentProcessId: 780,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T13:59:18.015Z",
          updatedAt: "2023-11-22T13:59:24.420Z",
          message: "",
        },
        {
          Id: 17449,
          DocumentProcessId: 780,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T13:59:18.271Z",
          updatedAt: "2023-11-22T13:59:24.536Z",
          message: "",
        },
        {
          Id: 17450,
          DocumentProcessId: 780,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T13:59:23.084Z",
          updatedAt: "2023-11-22T13:59:23.653Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17487,
          DocumentProcessId: 780,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T16:08:32.397Z",
          updatedAt: "2023-11-22T17:16:49.352Z",
          message: "",
        },
        {
          Id: 17488,
          DocumentProcessId: 780,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T16:08:33.224Z",
          updatedAt: "2023-11-22T17:16:49.030Z",
          message: "",
        },
        {
          Id: 17496,
          DocumentProcessId: 780,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T17:16:50.086Z",
          updatedAt: "2023-11-22T17:16:50.086Z",
          message: "",
        },
      ],
    },
    {
      Id: 779,
      DocumentUUID: "b8530127-8ab0-420d-8d2b-5b0a1dba0c72",
      BucketName: null,
      FileName: "Test-Hedis/0004_CT_0011_COA-04.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T13:51:06.904Z",
      updatedAt: "2023-11-22T13:51:06.904Z",
      DocumentProcessStatuses: [
        {
          Id: 17434,
          DocumentProcessId: 779,
          Status: "File Uploaded",
          createdAt: "2023-11-22T13:51:06.932Z",
          updatedAt: "2023-11-22T13:51:06.932Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17435,
          DocumentProcessId: 779,
          Status: "Loading File",
          createdAt: "2023-11-22T13:51:07.059Z",
          updatedAt: "2023-11-22T13:51:07.675Z",
          message: "",
        },
        {
          Id: 17436,
          DocumentProcessId: 779,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T13:51:07.704Z",
          updatedAt: "2023-11-22T13:51:08.251Z",
          message: "",
        },
        {
          Id: 17437,
          DocumentProcessId: 779,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T13:51:08.280Z",
          updatedAt: "2023-11-22T13:51:20.075Z",
          message: "",
        },
        {
          Id: 17438,
          DocumentProcessId: 779,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T13:51:15.241Z",
          updatedAt: "2023-11-22T13:51:20.644Z",
          message: "",
        },
        {
          Id: 17439,
          DocumentProcessId: 779,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T13:51:15.497Z",
          updatedAt: "2023-11-22T13:51:20.931Z",
          message: "",
        },
        {
          Id: 17440,
          DocumentProcessId: 779,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T13:51:20.114Z",
          updatedAt: "2023-11-22T13:51:20.583Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17491,
          DocumentProcessId: 779,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T17:02:25.057Z",
          updatedAt: "2023-11-22T17:16:47.687Z",
          message: "",
        },
        {
          Id: 17492,
          DocumentProcessId: 779,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T17:02:25.581Z",
          updatedAt: "2023-11-22T17:16:47.312Z",
          message: "",
        },
        {
          Id: 17495,
          DocumentProcessId: 779,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T17:16:48.401Z",
          updatedAt: "2023-11-22T17:16:48.401Z",
          message: "",
        },
      ],
    },
    {
      Id: 778,
      DocumentUUID: "d7799b84-a9ad-4a7a-ba01-422a7a00b7f3",
      BucketName: null,
      FileName: "Test-Hedis/0004_CT_003_COA-04.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T13:49:34.550Z",
      updatedAt: "2023-11-22T13:49:34.550Z",
      DocumentProcessStatuses: [
        {
          Id: 17427,
          DocumentProcessId: 778,
          Status: "File Uploaded",
          createdAt: "2023-11-22T13:49:34.578Z",
          updatedAt: "2023-11-22T13:49:34.578Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17428,
          DocumentProcessId: 778,
          Status: "Loading File",
          createdAt: "2023-11-22T13:49:34.752Z",
          updatedAt: "2023-11-22T13:49:35.225Z",
          message: "",
        },
        {
          Id: 17429,
          DocumentProcessId: 778,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T13:49:35.253Z",
          updatedAt: "2023-11-22T13:49:35.847Z",
          message: "",
        },
        {
          Id: 17430,
          DocumentProcessId: 778,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T13:49:35.877Z",
          updatedAt: "2023-11-22T13:49:42.332Z",
          message: "",
        },
        {
          Id: 17431,
          DocumentProcessId: 778,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T13:49:41.816Z",
          updatedAt: "2023-11-22T13:49:43.317Z",
          message: "",
        },
        {
          Id: 17432,
          DocumentProcessId: 778,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T13:49:42.221Z",
          updatedAt: "2023-11-22T13:49:43.679Z",
          message: "",
        },
        {
          Id: 17433,
          DocumentProcessId: 778,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T13:49:42.450Z",
          updatedAt: "2023-11-22T13:49:42.998Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17483,
          DocumentProcessId: 778,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T16:07:26.153Z",
          updatedAt: "2023-11-22T17:40:11.273Z",
          message: "",
        },
        {
          Id: 17484,
          DocumentProcessId: 778,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T16:07:26.831Z",
          updatedAt: "2023-11-22T17:40:10.948Z",
          message: "",
        },
        {
          Id: 17500,
          DocumentProcessId: 778,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T17:40:12.249Z",
          updatedAt: "2023-11-22T17:40:12.249Z",
          message: "",
        },
      ],
    },
    {
      Id: 777,
      DocumentUUID: "cb90cdc5-3e72-4d10-96c3-d648e3ded94a",
      BucketName: null,
      FileName: "Test-Hedis/0003_CT_0031_COA-03.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T13:48:19.061Z",
      updatedAt: "2023-11-22T13:48:19.061Z",
      DocumentProcessStatuses: [
        {
          Id: 17420,
          DocumentProcessId: 777,
          Status: "File Uploaded",
          createdAt: "2023-11-22T13:48:19.094Z",
          updatedAt: "2023-11-22T13:48:19.094Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17421,
          DocumentProcessId: 777,
          Status: "Loading File",
          createdAt: "2023-11-22T13:48:19.253Z",
          updatedAt: "2023-11-22T13:48:19.738Z",
          message: "",
        },
        {
          Id: 17422,
          DocumentProcessId: 777,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T13:48:19.776Z",
          updatedAt: "2023-11-22T13:48:20.244Z",
          message: "",
        },
        {
          Id: 17423,
          DocumentProcessId: 777,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T13:48:20.276Z",
          updatedAt: "2023-11-22T13:48:31.580Z",
          message: "",
        },
        {
          Id: 17424,
          DocumentProcessId: 777,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T13:48:27.312Z",
          updatedAt: "2023-11-22T13:48:33.219Z",
          message: "",
        },
        {
          Id: 17425,
          DocumentProcessId: 777,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T13:48:27.720Z",
          updatedAt: "2023-11-22T13:48:33.336Z",
          message: "",
        },
        {
          Id: 17426,
          DocumentProcessId: 777,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T13:48:31.611Z",
          updatedAt: "2023-11-22T13:48:32.108Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17481,
          DocumentProcessId: 777,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T16:06:24.824Z",
          updatedAt: "2023-11-22T17:39:59.783Z",
          message: "",
        },
        {
          Id: 17482,
          DocumentProcessId: 777,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T16:06:25.683Z",
          updatedAt: "2023-11-22T17:39:59.290Z",
          message: "",
        },
        {
          Id: 17499,
          DocumentProcessId: 777,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T17:40:00.543Z",
          updatedAt: "2023-11-22T17:40:00.543Z",
          message: "",
        },
      ],
    },
    {
      Id: 776,
      DocumentUUID: "c236f16c-911c-44b3-917d-7148581369ba",
      BucketName: null,
      FileName: "Test-Hedis/0002_CT_002_COA-02.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T13:47:02.818Z",
      updatedAt: "2023-11-22T13:47:02.818Z",
      DocumentProcessStatuses: [
        {
          Id: 17413,
          DocumentProcessId: 776,
          Status: "File Uploaded",
          createdAt: "2023-11-22T13:47:02.853Z",
          updatedAt: "2023-11-22T13:47:02.853Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17414,
          DocumentProcessId: 776,
          Status: "Loading File",
          createdAt: "2023-11-22T13:47:02.973Z",
          updatedAt: "2023-11-22T13:47:03.458Z",
          message: "",
        },
        {
          Id: 17415,
          DocumentProcessId: 776,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T13:47:03.489Z",
          updatedAt: "2023-11-22T13:47:05.043Z",
          message: "",
        },
        {
          Id: 17416,
          DocumentProcessId: 776,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T13:47:05.080Z",
          updatedAt: "2023-11-22T13:47:27.836Z",
          message: "",
        },
        {
          Id: 17417,
          DocumentProcessId: 776,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T13:47:12.161Z",
          updatedAt: "2023-11-22T13:47:28.432Z",
          message: "",
        },
        {
          Id: 17418,
          DocumentProcessId: 776,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T13:47:12.690Z",
          updatedAt: "2023-11-22T13:47:28.818Z",
          message: "",
        },
        {
          Id: 17419,
          DocumentProcessId: 776,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T13:47:27.866Z",
          updatedAt: "2023-11-22T13:47:28.160Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17477,
          DocumentProcessId: 776,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T15:54:37.073Z",
          updatedAt: "2023-11-22T17:24:08.219Z",
          message: "",
        },
        {
          Id: 17478,
          DocumentProcessId: 776,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T15:54:37.828Z",
          updatedAt: "2023-11-22T17:24:07.591Z",
          message: "",
        },
        {
          Id: 17494,
          DocumentProcessId: 776,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T17:16:36.650Z",
          updatedAt: "2023-11-22T17:24:09.408Z",
          message: "",
        },
      ],
    },
    {
      Id: 775,
      DocumentUUID: "eb02ec5d-0279-457b-b99b-5518b7bcb91e",
      BucketName: null,
      FileName: "Test-Hedis/0001_CT_001_COA-01.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T13:44:59.936Z",
      updatedAt: "2023-11-22T13:44:59.936Z",
      DocumentProcessStatuses: [
        {
          Id: 17406,
          DocumentProcessId: 775,
          Status: "File Uploaded",
          createdAt: "2023-11-22T13:44:59.945Z",
          updatedAt: "2023-11-22T13:44:59.945Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17407,
          DocumentProcessId: 775,
          Status: "Loading File",
          createdAt: "2023-11-22T13:45:00.148Z",
          updatedAt: "2023-11-22T13:45:01.063Z",
          message: "",
        },
        {
          Id: 17408,
          DocumentProcessId: 775,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T13:45:01.092Z",
          updatedAt: "2023-11-22T13:45:04.249Z",
          message: "",
        },
        {
          Id: 17409,
          DocumentProcessId: 775,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T13:45:04.280Z",
          updatedAt: "2023-11-22T13:46:12.029Z",
          message: "",
        },
        {
          Id: 17410,
          DocumentProcessId: 775,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T13:45:11.253Z",
          updatedAt: "2023-11-22T13:46:12.536Z",
          message: "",
        },
        {
          Id: 17411,
          DocumentProcessId: 775,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T13:45:11.522Z",
          updatedAt: "2023-11-22T13:46:12.753Z",
          message: "",
        },
        {
          Id: 17412,
          DocumentProcessId: 775,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T13:46:12.136Z",
          updatedAt: "2023-11-22T13:46:12.566Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17475,
          DocumentProcessId: 775,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T15:39:13.856Z",
          updatedAt: "2023-11-22T17:37:44.472Z",
          message: "",
        },
        {
          Id: 17476,
          DocumentProcessId: 775,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T15:39:14.382Z",
          updatedAt: "2023-11-22T17:37:44.171Z",
          message: "",
        },
        {
          Id: 17498,
          DocumentProcessId: 775,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T17:38:25.137Z",
          updatedAt: "2023-11-22T17:38:25.137Z",
          message: "",
        },
      ],
    },
    {
      Id: 774,
      DocumentUUID: "eadf44df-0758-4a92-bd3f-b2dc6ce650d9",
      BucketName: null,
      FileName: "Test-Hedis/0001_CIS-01.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T13:42:33.084Z",
      updatedAt: "2023-11-22T13:42:33.084Z",
      DocumentProcessStatuses: [
        {
          Id: 17399,
          DocumentProcessId: 774,
          Status: "File Uploaded",
          createdAt: "2023-11-22T13:42:33.092Z",
          updatedAt: "2023-11-22T13:42:33.092Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17400,
          DocumentProcessId: 774,
          Status: "Loading File",
          createdAt: "2023-11-22T13:42:33.255Z",
          updatedAt: "2023-11-22T13:42:34.254Z",
          message: "",
        },
        {
          Id: 17401,
          DocumentProcessId: 774,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T13:42:34.342Z",
          updatedAt: "2023-11-22T13:42:37.055Z",
          message: "",
        },
        {
          Id: 17402,
          DocumentProcessId: 774,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T13:42:37.142Z",
          updatedAt: "2023-11-22T13:44:03.315Z",
          message: "",
        },
        {
          Id: 17403,
          DocumentProcessId: 774,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T13:42:44.535Z",
          updatedAt: "2023-11-22T13:44:03.735Z",
          message: "",
        },
        {
          Id: 17404,
          DocumentProcessId: 774,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T13:42:44.744Z",
          updatedAt: "2023-11-22T13:44:03.894Z",
          message: "",
        },
        {
          Id: 17405,
          DocumentProcessId: 774,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T13:44:03.355Z",
          updatedAt: "2023-11-22T13:44:03.641Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17441,
          DocumentProcessId: 774,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T13:51:51.938Z",
          updatedAt: "2023-11-22T17:35:24.438Z",
          message: "",
        },
        {
          Id: 17442,
          DocumentProcessId: 774,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T13:51:52.356Z",
          updatedAt: "2023-11-22T17:35:23.776Z",
          message: "",
        },
        {
          Id: 17497,
          DocumentProcessId: 774,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T17:35:46.940Z",
          updatedAt: "2023-11-22T17:35:46.940Z",
          message: "",
        },
      ],
    },
    {
      Id: 773,
      DocumentUUID: "18b680f2-35fa-41d2-ad6c-0f8d54c10583",
      BucketName: null,
      FileName: "Test-Hedis/0004_CCS-04.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T13:32:46.345Z",
      updatedAt: "2023-11-22T13:32:46.346Z",
      DocumentProcessStatuses: [
        {
          Id: 17388,
          DocumentProcessId: 773,
          Status: "File Uploaded",
          createdAt: "2023-11-22T13:32:46.374Z",
          updatedAt: "2023-11-22T13:32:46.374Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17389,
          DocumentProcessId: 773,
          Status: "Loading File",
          createdAt: "2023-11-22T13:32:46.547Z",
          updatedAt: "2023-11-22T13:32:47.512Z",
          message: "",
        },
        {
          Id: 17390,
          DocumentProcessId: 773,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T13:32:47.546Z",
          updatedAt: "2023-11-22T13:32:47.653Z",
          message: "",
        },
        {
          Id: 17391,
          DocumentProcessId: 773,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T13:32:47.740Z",
          updatedAt: "2023-11-22T13:33:06.915Z",
          message: "",
        },
        {
          Id: 17392,
          DocumentProcessId: 773,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T13:32:54.634Z",
          updatedAt: "2023-11-22T13:33:07.576Z",
          message: "",
        },
        {
          Id: 17393,
          DocumentProcessId: 773,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T13:32:54.995Z",
          updatedAt: "2023-11-22T13:33:07.901Z",
          message: "",
        },
        {
          Id: 17394,
          DocumentProcessId: 773,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T13:33:06.945Z",
          updatedAt: "2023-11-22T13:33:07.301Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17397,
          DocumentProcessId: 773,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T13:37:24.926Z",
          updatedAt: "2023-11-22T15:24:24.506Z",
          message: "",
        },
        {
          Id: 17398,
          DocumentProcessId: 773,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T13:37:25.471Z",
          updatedAt: "2023-11-22T15:24:23.973Z",
          message: "",
        },
        {
          Id: 17474,
          DocumentProcessId: 773,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T15:15:22.427Z",
          updatedAt: "2023-11-22T15:24:25.627Z",
          message: "",
        },
      ],
    },
    {
      Id: 772,
      DocumentUUID: "ffdb8de4-ad12-4da5-ae79-b9b0c0788fdd",
      BucketName: null,
      FileName: "Test-Hedis/0003_CCS-03.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T13:23:28.325Z",
      updatedAt: "2023-11-22T13:23:28.325Z",
      DocumentProcessStatuses: [
        {
          Id: 17381,
          DocumentProcessId: 772,
          Status: "File Uploaded",
          createdAt: "2023-11-22T13:23:28.361Z",
          updatedAt: "2023-11-22T13:23:28.362Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17382,
          DocumentProcessId: 772,
          Status: "Loading File",
          createdAt: "2023-11-22T13:23:28.457Z",
          updatedAt: "2023-11-22T13:23:29.139Z",
          message: "",
        },
        {
          Id: 17383,
          DocumentProcessId: 772,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T13:23:29.168Z",
          updatedAt: "2023-11-22T13:23:31.048Z",
          message: "",
        },
        {
          Id: 17384,
          DocumentProcessId: 772,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T13:23:31.078Z",
          updatedAt: "2023-11-22T13:24:05.827Z",
          message: "",
        },
        {
          Id: 17385,
          DocumentProcessId: 772,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T13:23:38.610Z",
          updatedAt: "2023-11-22T13:24:06.208Z",
          message: "",
        },
        {
          Id: 17386,
          DocumentProcessId: 772,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T13:23:39.126Z",
          updatedAt: "2023-11-22T13:24:06.365Z",
          message: "",
        },
        {
          Id: 17387,
          DocumentProcessId: 772,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T13:24:05.856Z",
          updatedAt: "2023-11-22T13:24:06.376Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17395,
          DocumentProcessId: 772,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T13:36:43.186Z",
          updatedAt: "2023-11-22T15:14:05.840Z",
          message: "",
        },
        {
          Id: 17396,
          DocumentProcessId: 772,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T13:36:43.637Z",
          updatedAt: "2023-11-22T15:14:05.404Z",
          message: "",
        },
        {
          Id: 17473,
          DocumentProcessId: 772,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T15:14:07.242Z",
          updatedAt: "2023-11-22T15:14:07.243Z",
          message: "",
        },
      ],
    },
    {
      Id: 771,
      DocumentUUID: "d3a8d89c-2b68-497d-b9f9-d21814bbf3c5",
      BucketName: null,
      FileName: "Test-Hedis/0002_CCS-02.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T13:21:53.875Z",
      updatedAt: "2023-11-22T13:21:53.875Z",
      DocumentProcessStatuses: [
        {
          Id: 17372,
          DocumentProcessId: 771,
          Status: "File Uploaded",
          createdAt: "2023-11-22T13:21:53.921Z",
          updatedAt: "2023-11-22T13:21:53.921Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17373,
          DocumentProcessId: 771,
          Status: "Loading File",
          createdAt: "2023-11-22T13:21:54.142Z",
          updatedAt: "2023-11-22T13:21:55.137Z",
          message: "",
        },
        {
          Id: 17374,
          DocumentProcessId: 771,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T13:21:55.227Z",
          updatedAt: "2023-11-22T13:21:58.457Z",
          message: "",
        },
        {
          Id: 17375,
          DocumentProcessId: 771,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T13:21:58.543Z",
          updatedAt: "2023-11-22T13:22:55.619Z",
          message: "",
        },
        {
          Id: 17376,
          DocumentProcessId: 771,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T13:22:05.425Z",
          updatedAt: "2023-11-22T13:22:56.238Z",
          message: "",
        },
        {
          Id: 17377,
          DocumentProcessId: 771,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T13:22:05.607Z",
          updatedAt: "2023-11-22T13:22:56.400Z",
          message: "",
        },
        {
          Id: 17378,
          DocumentProcessId: 771,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T13:22:06.333Z",
          updatedAt: "2023-11-22T15:11:25.791Z",
          message: "",
        },
        {
          Id: 17379,
          DocumentProcessId: 771,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T13:22:06.837Z",
          updatedAt: "2023-11-22T15:11:24.782Z",
          message: "",
        },
        {
          Id: 17380,
          DocumentProcessId: 771,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T13:22:56.128Z",
          updatedAt: "2023-11-22T13:22:56.442Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17472,
          DocumentProcessId: 771,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T15:11:31.321Z",
          updatedAt: "2023-11-22T15:11:31.321Z",
          message: "",
        },
      ],
    },
    {
      Id: 770,
      DocumentUUID: "a943d6a7-aa9d-4549-9dd5-448e09962af4",
      BucketName: null,
      FileName: "Test-Hedis/0001_CCS-01.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T13:11:03.480Z",
      updatedAt: "2023-11-22T13:11:03.480Z",
      DocumentProcessStatuses: [
        {
          Id: 17363,
          DocumentProcessId: 770,
          Status: "File Uploaded",
          createdAt: "2023-11-22T13:11:03.503Z",
          updatedAt: "2023-11-22T13:11:03.503Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17364,
          DocumentProcessId: 770,
          Status: "Loading File",
          createdAt: "2023-11-22T13:11:03.648Z",
          updatedAt: "2023-11-22T13:11:04.522Z",
          message: "",
        },
        {
          Id: 17365,
          DocumentProcessId: 770,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T13:11:04.556Z",
          updatedAt: "2023-11-22T13:11:07.751Z",
          message: "",
        },
        {
          Id: 17366,
          DocumentProcessId: 770,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T13:11:07.781Z",
          updatedAt: "2023-11-22T13:11:30.523Z",
          message: "",
        },
        {
          Id: 17367,
          DocumentProcessId: 770,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T13:11:14.834Z",
          updatedAt: "2023-11-22T13:11:31.451Z",
          message: "",
        },
        {
          Id: 17368,
          DocumentProcessId: 770,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T13:11:15.036Z",
          updatedAt: "2023-11-22T13:11:31.761Z",
          message: "",
        },
        {
          Id: 17369,
          DocumentProcessId: 770,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T13:11:15.654Z",
          updatedAt: "2023-11-22T13:53:01.228Z",
          message: "",
        },
        {
          Id: 17370,
          DocumentProcessId: 770,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T13:11:15.963Z",
          updatedAt: "2023-11-22T13:53:00.457Z",
          message: "",
        },
        {
          Id: 17371,
          DocumentProcessId: 770,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T13:11:30.553Z",
          updatedAt: "2023-11-22T13:11:30.846Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17443,
          DocumentProcessId: 770,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T13:52:08.817Z",
          updatedAt: "2023-11-22T13:53:02.421Z",
          message: "",
        },
      ],
    },
    {
      Id: 769,
      DocumentUUID: "f18edac5-dcd8-4825-842e-a584bf492488",
      BucketName: null,
      FileName: "Test-Hedis/0003_CBP-03.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T10:39:54.925Z",
      updatedAt: "2023-11-22T10:39:54.925Z",
      DocumentProcessStatuses: [
        {
          Id: 17352,
          DocumentProcessId: 769,
          Status: "File Uploaded",
          createdAt: "2023-11-22T10:39:54.949Z",
          updatedAt: "2023-11-22T10:39:54.950Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17353,
          DocumentProcessId: 769,
          Status: "Loading File",
          createdAt: "2023-11-22T10:39:55.147Z",
          updatedAt: "2023-11-22T10:39:55.655Z",
          message: "",
        },
        {
          Id: 17354,
          DocumentProcessId: 769,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T10:39:55.685Z",
          updatedAt: "2023-11-22T10:39:56.645Z",
          message: "",
        },
        {
          Id: 17355,
          DocumentProcessId: 769,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T10:39:56.740Z",
          updatedAt: "2023-11-22T10:40:30.257Z",
          message: "",
        },
        {
          Id: 17356,
          DocumentProcessId: 769,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T10:40:03.305Z",
          updatedAt: "2023-11-22T10:40:31.145Z",
          message: "",
        },
        {
          Id: 17357,
          DocumentProcessId: 769,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T10:40:03.956Z",
          updatedAt: "2023-11-22T10:40:31.823Z",
          message: "",
        },
        {
          Id: 17358,
          DocumentProcessId: 769,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T10:40:24.024Z",
          updatedAt: "2023-11-22T12:14:17.283Z",
          message: "",
        },
        {
          Id: 17359,
          DocumentProcessId: 769,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T10:40:24.332Z",
          updatedAt: "2023-11-22T12:14:16.918Z",
          message: "",
        },
        {
          Id: 17360,
          DocumentProcessId: 769,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T10:40:30.287Z",
          updatedAt: "2023-11-22T10:40:30.701Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17362,
          DocumentProcessId: 769,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T12:14:19.731Z",
          updatedAt: "2023-11-22T12:14:19.731Z",
          message: "",
        },
      ],
    },
    {
      Id: 768,
      DocumentUUID: "2aa09a13-a0e7-4850-956c-d34295c02893",
      BucketName: null,
      FileName: "Test-Hedis/0002_CBP-02.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T10:38:08.056Z",
      updatedAt: "2023-11-22T10:38:08.056Z",
      DocumentProcessStatuses: [
        {
          Id: 17342,
          DocumentProcessId: 768,
          Status: "File Uploaded",
          createdAt: "2023-11-22T10:38:08.102Z",
          updatedAt: "2023-11-22T10:38:08.103Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17343,
          DocumentProcessId: 768,
          Status: "Loading File",
          createdAt: "2023-11-22T10:38:08.242Z",
          updatedAt: "2023-11-22T10:38:08.645Z",
          message: "",
        },
        {
          Id: 17344,
          DocumentProcessId: 768,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T10:38:08.677Z",
          updatedAt: "2023-11-22T10:38:08.939Z",
          message: "",
        },
        {
          Id: 17345,
          DocumentProcessId: 768,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T10:38:08.966Z",
          updatedAt: "2023-11-22T10:38:51.144Z",
          message: "",
        },
        {
          Id: 17346,
          DocumentProcessId: 768,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T10:38:16.209Z",
          updatedAt: "2023-11-22T10:38:52.212Z",
          message: "",
        },
        {
          Id: 17347,
          DocumentProcessId: 768,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T10:38:16.599Z",
          updatedAt: "2023-11-22T10:38:52.474Z",
          message: "",
        },
        {
          Id: 17348,
          DocumentProcessId: 768,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T10:38:16.910Z",
          updatedAt: "2023-11-22T11:23:40.635Z",
          message: "",
        },
        {
          Id: 17349,
          DocumentProcessId: 768,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T10:38:17.412Z",
          updatedAt: "2023-11-22T11:23:40.162Z",
          message: "",
        },
        {
          Id: 17350,
          DocumentProcessId: 768,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T10:38:51.172Z",
          updatedAt: "2023-11-22T10:38:51.621Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17361,
          DocumentProcessId: 768,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T11:23:44.829Z",
          updatedAt: "2023-11-22T11:23:44.829Z",
          message: "",
        },
      ],
    },
    {
      Id: 767,
      DocumentUUID: "82666c14-209c-4f49-9f04-f3caa2395b29",
      BucketName: null,
      FileName: "Test-Hedis/0001_CBP-01.pdf",
      ProjectId: 3,
      createdAt: "2023-11-22T09:43:49.455Z",
      updatedAt: "2023-11-22T09:43:49.456Z",
      DocumentProcessStatuses: [
        {
          Id: 17333,
          DocumentProcessId: 767,
          Status: "File Uploaded",
          createdAt: "2023-11-22T09:43:49.493Z",
          updatedAt: "2023-11-22T09:43:49.493Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17334,
          DocumentProcessId: 767,
          Status: "Loading File",
          createdAt: "2023-11-22T09:43:49.648Z",
          updatedAt: "2023-11-22T09:43:50.165Z",
          message: "",
        },
        {
          Id: 17335,
          DocumentProcessId: 767,
          Status: "Extracting Pages",
          createdAt: "2023-11-22T09:43:50.198Z",
          updatedAt: "2023-11-22T09:43:53.357Z",
          message: "",
        },
        {
          Id: 17336,
          DocumentProcessId: 767,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-22T09:43:53.443Z",
          updatedAt: "2023-11-22T09:44:26.007Z",
          message: "",
        },
        {
          Id: 17337,
          DocumentProcessId: 767,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-22T09:44:00.797Z",
          updatedAt: "2023-11-22T09:44:26.452Z",
          message: "",
        },
        {
          Id: 17338,
          DocumentProcessId: 767,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-22T09:44:01.446Z",
          updatedAt: "2023-11-22T09:44:26.625Z",
          message: "",
        },
        {
          Id: 17339,
          DocumentProcessId: 767,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-22T09:44:01.930Z",
          updatedAt: "2023-11-22T10:39:07.430Z",
          message: "",
        },
        {
          Id: 17340,
          DocumentProcessId: 767,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-22T09:44:02.622Z",
          updatedAt: "2023-11-22T10:39:06.920Z",
          message: "",
        },
        {
          Id: 17341,
          DocumentProcessId: 767,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-22T09:44:26.036Z",
          updatedAt: "2023-11-22T09:44:26.262Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17351,
          DocumentProcessId: 767,
          Status: "SUCCEEDED",
          createdAt: "2023-11-22T10:39:09.542Z",
          updatedAt: "2023-11-22T10:39:09.542Z",
          message: "",
        },
      ],
    },
    {
      Id: 766,
      DocumentUUID: "dcf6d754-e4c5-421f-89fc-fba7484aad03",
      BucketName: null,
      FileName: "test/0004_TEST_BPD.pdf",
      ProjectId: null,
      createdAt: "2023-11-21T11:36:43.125Z",
      updatedAt: "2023-11-21T11:36:43.125Z",
      DocumentProcessStatuses: [
        {
          Id: 17322,
          DocumentProcessId: 766,
          Status: "File Uploaded",
          createdAt: "2023-11-21T11:36:43.162Z",
          updatedAt: "2023-11-21T11:36:43.162Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17323,
          DocumentProcessId: 766,
          Status: "Loading File",
          createdAt: "2023-11-21T11:36:43.247Z",
          updatedAt: "2023-11-21T11:36:44.029Z",
          message: "",
        },
        {
          Id: 17324,
          DocumentProcessId: 766,
          Status: "Extracting Pages",
          createdAt: "2023-11-21T11:36:44.060Z",
          updatedAt: "2023-11-21T11:36:45.146Z",
          message: "",
        },
        {
          Id: 17325,
          DocumentProcessId: 766,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-21T11:36:45.235Z",
          updatedAt: "2023-11-21T11:37:07.866Z",
          message: "",
        },
        {
          Id: 17326,
          DocumentProcessId: 766,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-21T11:36:52.306Z",
          updatedAt: "2023-11-21T11:37:08.438Z",
          message: "",
        },
        {
          Id: 17327,
          DocumentProcessId: 766,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-21T11:36:52.610Z",
          updatedAt: "2023-11-21T11:37:08.704Z",
          message: "",
        },
        {
          Id: 17328,
          DocumentProcessId: 766,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-21T11:36:53.737Z",
          updatedAt: "2023-11-21T12:42:44.177Z",
          message: "",
        },
        {
          Id: 17329,
          DocumentProcessId: 766,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-21T11:36:54.087Z",
          updatedAt: "2023-11-21T12:42:43.523Z",
          message: "",
        },
        {
          Id: 17330,
          DocumentProcessId: 766,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-21T11:37:07.924Z",
          updatedAt: "2023-11-21T11:37:08.123Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17332,
          DocumentProcessId: 766,
          Status: "SUCCEEDED",
          createdAt: "2023-11-21T12:42:47.463Z",
          updatedAt: "2023-11-21T12:42:47.463Z",
          message: "",
        },
      ],
    },
    {
      Id: 765,
      DocumentUUID: "2ee96bab-ffe3-4dfd-9743-58cd500f887d",
      BucketName: null,
      FileName: "test/0003_TEST_BPD.pdf",
      ProjectId: null,
      createdAt: "2023-11-21T11:23:27.635Z",
      updatedAt: "2023-11-21T11:23:27.635Z",
      DocumentProcessStatuses: [
        {
          Id: 17313,
          DocumentProcessId: 765,
          Status: "File Uploaded",
          createdAt: "2023-11-21T11:23:27.689Z",
          updatedAt: "2023-11-21T11:23:27.689Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17314,
          DocumentProcessId: 765,
          Status: "Loading File",
          createdAt: "2023-11-21T11:23:27.829Z",
          updatedAt: "2023-11-21T11:23:28.493Z",
          message: "",
        },
        {
          Id: 17315,
          DocumentProcessId: 765,
          Status: "Extracting Pages",
          createdAt: "2023-11-21T11:23:28.523Z",
          updatedAt: "2023-11-21T11:23:28.732Z",
          message: "",
        },
        {
          Id: 17316,
          DocumentProcessId: 765,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-21T11:23:28.758Z",
          updatedAt: "2023-11-21T11:24:04.639Z",
          message: "",
        },
        {
          Id: 17317,
          DocumentProcessId: 765,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-21T11:23:35.821Z",
          updatedAt: "2023-11-21T11:24:04.823Z",
          message: "",
        },
        {
          Id: 17318,
          DocumentProcessId: 765,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-21T11:23:36.406Z",
          updatedAt: "2023-11-21T11:24:04.992Z",
          message: "",
        },
        {
          Id: 17319,
          DocumentProcessId: 765,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-21T11:23:36.942Z",
          updatedAt: "2023-11-21T12:18:50.292Z",
          message: "",
        },
        {
          Id: 17320,
          DocumentProcessId: 765,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-21T11:23:38.140Z",
          updatedAt: "2023-11-21T12:18:49.485Z",
          message: "",
        },
        {
          Id: 17321,
          DocumentProcessId: 765,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-21T11:24:04.677Z",
          updatedAt: "2023-11-21T11:24:05.070Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17331,
          DocumentProcessId: 765,
          Status: "COMPLETED WITH ERROR",
          createdAt: "2023-11-21T12:18:51.963Z",
          updatedAt: "2023-11-21T12:18:51.964Z",
          message: "",
        },
      ],
    },
    {
      Id: 764,
      DocumentUUID: "110131e6-8f8e-4e27-a195-15623f645995",
      BucketName: null,
      FileName: "test/0002_TEST_CT_002_BPD.pdf",
      ProjectId: null,
      createdAt: "2023-11-21T05:23:23.397Z",
      updatedAt: "2023-11-21T05:23:23.397Z",
      DocumentProcessStatuses: [
        {
          Id: 17303,
          DocumentProcessId: 764,
          Status: "File Uploaded",
          createdAt: "2023-11-21T05:23:23.419Z",
          updatedAt: "2023-11-21T05:23:23.419Z",
          message: "This file has been successfully uploaded.",
        },
        {
          Id: 17304,
          DocumentProcessId: 764,
          Status: "Loading File",
          createdAt: "2023-11-21T05:23:23.549Z",
          updatedAt: "2023-11-21T05:23:24.876Z",
          message: "",
        },
        {
          Id: 17305,
          DocumentProcessId: 764,
          Status: "Extracting Pages",
          createdAt: "2023-11-21T05:23:24.914Z",
          updatedAt: "2023-11-21T05:23:26.735Z",
          message: "",
        },
        {
          Id: 17306,
          DocumentProcessId: 764,
          Status: "Processing Pages for Data extraction (OCR)",
          createdAt: "2023-11-21T05:23:26.767Z",
          updatedAt: "2023-11-21T05:24:01.419Z",
          message: "",
        },
        {
          Id: 17307,
          DocumentProcessId: 764,
          Status: "Clinical LLM Linking",
          createdAt: "2023-11-21T05:23:34.739Z",
          updatedAt: "2023-11-21T05:24:02.219Z",
          message: "",
        },
        {
          Id: 17308,
          DocumentProcessId: 764,
          Status: "ICD10 Mapping",
          createdAt: "2023-11-21T05:23:35.270Z",
          updatedAt: "2023-11-21T05:24:02.326Z",
          message: "",
        },
        {
          Id: 17309,
          DocumentProcessId: 764,
          Status: "Extracting Hedis Insights",
          createdAt: "2023-11-21T05:23:36.041Z",
          updatedAt: "2023-11-21T07:29:26.686Z",
          message: "",
        },
        {
          Id: 17310,
          DocumentProcessId: 764,
          Status: "Summarization using Generative AI",
          createdAt: "2023-11-21T05:23:36.641Z",
          updatedAt: "2023-11-21T07:29:26.025Z",
          message: "",
        },
        {
          Id: 17311,
          DocumentProcessId: 764,
          Status: "Clinical Data Extraction Started",
          createdAt: "2023-11-21T05:24:01.450Z",
          updatedAt: "2023-11-21T05:24:01.721Z",
          message: "The information extraction process for this file has initiated.",
        },
        {
          Id: 17312,
          DocumentProcessId: 764,
          Status: "SUCCEEDED",
          createdAt: "2023-11-21T07:29:28.341Z",
          updatedAt: "2023-11-21T07:29:28.341Z",
          message: "",
        },
      ],
    },
  ],
  pagination: { totalCount: 340, offset: 0, limit: 20 },
  transaction_id: "e5b8031a-39ea-4710-92a3-6cb63a473b5f",
};


export const STATUS_COLORS = {
  COMPLETED: "#44c658", // Green
  SUCCEEDED: "#44c658", // Green
  ERROR: "#f44336",      // Red
  ON_HOLD: "#FFD300",    // Yellow
  PROCESSING: "#FFD300", // Yellow
  FETCHING: "#4743db",  
  IN_REVIEW: "#4743db",        // Blue
  PROCESS_COMPLETE: "#44c658", // Green
  NOT_COMPLETE: "#f44336",     // Red
  SEARCH_SUCCESS: "#44c658",             // Green
  CLAIM_ID_NOT_FOUND: "#f44336",         // Red
  CLAIM_DETAILS_NOT_FOUND: "#ff9800",    // Orange
  EDIT_CODE_NOT_IN_SCOPE: "#FFD300",     // Yellow
  UNKNOWN_ERROR: "#f44336",   // Light blue
  DEFAULT: "#475569"     // Grayish-blue
};


export const CLAIMS_DOCUMENTS = {
  "data": [
      {
          "Id": 1258,
          "DocumentUUID": "f421eeb5-9bc8-4058-a0d0-b9d0c5cf41c1",
          "FileName": "PCP 32 MC.pdf",
          "TotalPages": 10,
          "Status": "Failed",
          "CreatedAt": "2024-10-30T17:55:09.360Z",
          "UpdatedAt": "2024-10-30T17:55:36.750Z",
          "ExecutionTime": 27.39
      },
      {
          "Id": 1257,
          "DocumentUUID": "8cb65319-5788-4658-9d97-a9e1ec42af93",
          "FileName": "PCP 22 ICB.pdf",
          "TotalPages": 5,
          "Status": "Succeeded",
          "CreatedAt": "2024-10-30T17:51:09.270Z",
          "UpdatedAt": "2024-10-30T17:51:26.093Z",
          "ExecutionTime": 16.823
      },
      {
          "Id": 1256,
          "DocumentUUID": "59df99aa-62ca-4b33-a70c-407b3d148a52",
          "FileName": "PCP 11 RRRR.pdf",
          "TotalPages": 15,
          "Status": "Processing",
          "CreatedAt": "2024-10-30T12:06:09.377Z",
          "UpdatedAt": "2024-10-30T12:06:21.883Z",
          "ExecutionTime": 12.506
      },
      {
          "Id": 1255,
          "DocumentUUID": "6ef1c38e-416f-44d8-bcf3-27097a7bcd28",
          "FileName": "Clotrimazole-protected_1.pdf",
          "TotalPages": 0,
          "Status": "Fetching",
          "CreatedAt": "2024-10-30T11:51:17.583Z",
          "UpdatedAt": "2024-10-30T11:51:17.913Z",
          "ExecutionTime": 0.33
      },
      {
          "Id": 1254,
          "DocumentUUID": "d398f217-cbb6-438e-a2fc-a8adc3cd10f0",
          "FileName": "PCP 40 AJW.pdf",
          "TotalPages": 8,
          "Status": "Failed",
          "CreatedAt": "2024-10-30T11:45:09.407Z",
          "UpdatedAt": "2024-10-30T11:45:28.743Z",
          "ExecutionTime": 19.336
      }
  ],
  "pagination": {
      "totalCount": 1126,
      "offset": 0,
      "limit": 5
  },
  "transaction_id": "dd45e4b3-4ed6-4907-9cba-af76b8e8dd21"
}


export const CLAIMS_PAGE_DATA = {
  "data": [
      {
          "Id": 3001,
          "ClaimDocumentId": 1120,
          "PageNumber": 1,
          "Status": "VALIDATION",
          "CreatedAt": "2024-11-04T10:45:30.000Z",
          "UpdatedAt": "2024-11-04T10:46:15.000Z",
          "historyStats": [
              {
                  "Step": "VALIDATION",
                  "StartTime": "2024-11-04T10:46:00.000Z",
                  "EndTime": "2024-11-04T10:46:15.000Z"
              },
              {
                  "Step": "EXTRACTION",
                  "StartTime": "2024-11-04T10:45:30.000Z",
                  "EndTime": "2024-11-04T10:45:50.000Z"
              }
          ]
      },
      {
          "Id": 3002,
          "ClaimDocumentId": 1120,
          "PageNumber": 2,
          "Status": "VALIDATION",
          "CreatedAt": "2024-11-04T10:45:30.000Z",
          "UpdatedAt": "2024-11-04T10:46:20.000Z",
          "historyStats": [
              {
                  "Step": "VALIDATION",
                  "StartTime": "2024-11-04T10:46:10.000Z",
                  "EndTime": "2024-11-04T10:46:20.000Z"
              },
              {
                  "Step": "EXTRACTION",
                  "StartTime": "2024-11-04T10:45:30.000Z",
                  "EndTime": "2024-11-04T10:45:55.000Z"
              }
          ]
      }
  ],
  "transaction_id": "a5f4c1d2-b23e-4aef-b8f0-12345exampleid"
}


export const CLAIMS_PAGE_STATUSES = [
  {
      "Id": 4001,
      "ClaimDocumentId": 1120,
      "Status": "Fetching",
      "CreatedAt": "2024-11-04T10:50:09.397Z",
      "UpdatedAt": "2024-11-04T10:50:09.397Z"
  },
  {
      "Id": 4002,
      "ClaimDocumentId": 1120,
      "Status": "Processing",
      "CreatedAt": "2024-11-04T10:50:10.010Z",
      "UpdatedAt": "2024-11-04T10:50:10.013Z"
  },
  {
      "Id": 4003,
      "ClaimDocumentId": 1120,
      "Status": "Failed",
      "CreatedAt": "2024-11-04T10:50:36.757Z",
      "UpdatedAt": "2024-11-04T10:50:36.757Z"
  }
]

export const CLAIMS_API_STATS_DATA = {
  data: {
    "message": "OK",
    "data": {
      "2024-10-25": [
        {
          "service_name": "Claims",
          "moduleName": "Search Initiated",
          "token_count": "0",
          "date": "2024-10-25"
        }
      ],
      "2024-10-26": [
        {
          "service_name": "Claims",
          "moduleName": "Search Failed",
          "token_count": "25000",
          "date": "2024-10-26"
        }
      ],
      "2024-10-27": [
        {
          "service_name": "Claims",
          "moduleName": "Search Success",
          "token_count": "48000",
          "date": "2024-10-27"
        }
      ],
      "2024-10-28": [
        {
          "service_name": "Claims",
          "moduleName": "Search Failed",
          "token_count": "0",
          "date": "2024-10-28"
        }
      ],
      "2024-10-29": [
        {
          "service_name": "Claims",
          "moduleName": "Search Initiated",
          "token_count": "120000",
          "date": "2024-10-29"
        }
      ]
    },
    "transaction_id": "d85e7612-5a23-45d7-b95b-0e4d3475f1d8"
  }
};


export const CLAIMS_CHARTS_DATA = {
  "message": "OK",
  "data": [
      {
          "processed": 20,
          "date": "2024-11-01",
          "status": "SUCCESS",
          "service": "claims"
      },
      {
          "processed": 5,
          "date": "2024-11-01",
          "status": "FAILED",
          "service": "claims"
      },
      {
          "processed": 30,
          "date": "2024-11-02",
          "status": "SUCCESS",
          "service": "claims"
      },
      {
          "processed": 10,
          "date": "2024-11-03",
          "status": "SUCCESS",
          "service": "claims"
      },
      {
          "processed": 3,
          "date": "2024-11-04",
          "status": "FAILED",
          "service": "claims"
      },
      {
          "processed": 12,
          "date": "2024-11-05",
          "status": "SUCCESS",
          "service": "claims"
      },
      {
          "processed": 45,
          "date": "2024-11-06",
          "status": "SUCCESS",
          "service": "claims"
      },
      {
          "processed": 2,
          "date": "2024-11-06",
          "status": "FAILED",
          "service": "claims"
      },
      {
          "processed": 50,
          "date": "2024-11-07",
          "status": "SUCCESS",
          "service": "claims"
      },
      {
          "processed": 40,
          "date": "2024-11-08",
          "status": "SUCCESS",
          "service": "claims"
      },
      {
          "processed": 5,
          "date": "2024-11-08",
          "status": "FAILED",
          "service": "claims"
      },
      {
          "processed": 60,
          "date": "2024-11-09",
          "status": "SUCCESS",
          "service": "claims"
      },
      {
          "processed": 4,
          "date": "2024-11-09",
          "status": "FAILED",
          "service": "claims"
      },
      {
          "processed": 78,
          "date": "2024-11-10",
          "status": "SUCCESS",
          "service": "claims"
      },
      {
          "processed": 2,
          "date": "2024-11-10",
          "status": "FAILED",
          "service": "claims"
      },
      {
          "processed": 67,
          "date": "2024-11-11",
          "status": "SUCCESS",
          "service": "claims"
      },
      {
          "processed": 15,
          "date": "2024-11-12",
          "status": "FAILED",
          "service": "claims"
      }
  ],
  "transaction_id": "93bf2a67-6528-42c4-8f58-de8c8b768a30"
}

export const CLIAMS_TOKEN_UTILISATION_DATA = {
  data:{
    "data": [
        {
            "date": "2024-10-25",
            "total_token_count": "3450",
            "total_completion_tokens": "250",
            "total_prompt_tokens": "3200"
        },
        {
            "date": "2024-10-26",
            "total_token_count": "15800",
            "total_completion_tokens": "850",
            "total_prompt_tokens": "14950"
        },
        {
            "date": "2024-10-27",
            "total_token_count": "28490",
            "total_completion_tokens": "1200",
            "total_prompt_tokens": "27290"
        },
        {
            "date": "2024-10-28",
            "total_token_count": "49200",
            "total_completion_tokens": "1780",
            "total_prompt_tokens": "47420"
        }
    ],
  "transaction_id": "8b2f4b12-62f3-43b1-9ad2-e17a897ac0de"
}
}

export const CLAIMS_HEALTH_STATUS = {
  "data": [
      {
          "service_name": "Claims Backend",
          "description": "Service handling backend operations for claims processing.",
          "last_25_pings": [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
          "current_status": 1,
          "availability": "100.00",
          "last_failed_at": null
      },
      {
          "service_name": "Claims ML",
          "description": "Machine learning service for claims data processing.",
          "last_25_pings": [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
          "current_status": 1,
          "availability": "96.00",
          "last_failed_at": null
      },
      {
          "service_name": "Claims ML Worker",
          "description": "Worker service for handling asynchronous ML tasks in claims processing.",
          "last_25_pings": [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
          "current_status": 1,
          "availability": "98.00",
          "last_failed_at": null
      },
      {
          "service_name": "RBAC",
          "description": "Service for role-based access control and user permissions.",
          "last_25_pings": [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
          "current_status": 1,
          "availability": "100.00",
          "last_failed_at": null
      },
      {
          "service_name": "Auth",
          "description": "Service managing user authentication and authorization.",
          "last_25_pings": [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
          "current_status": 1,
          "availability": "100.00",
          "last_failed_at": null
      }
  ],
  "status": "OK",
  "transaction_id": "abc12345-6789-1234-5678-abcdef987654"
}
