export enum USER_ROLES_CODE {
  ADMIN = "SUPER_ADMIN",
  DATA_SCIENTIST = "DATA_SCIENTIST",
  BUSINESS_USER = "BUSINESS_USER",
}

export const DATA_ADMIN_PERMISSIONS = ["03", "05"];

export enum MODAL_TYPE {
  ADD_EDIT_PROJECT = "addEditProject",
  DELETE_CONFIRMATION = "deleteConfirmation",
  EDIT_PROFILE = "editProfile",
  FORGOT_PASSWORD = "forgotPassword",
  PAGES_STATUS = "pagesStatus",
  DOCUMENT_PROCESSING_DETAIL_STATUS = "documentProcessingDetailStatus",
  LICENSE_KEY = "licenseKey",
  VIEW_MEDICAL_ENTITIES = "viewMedicalEntities",
  PERMISSIONS = "permissions",
  DOCUMENT_PROCESSING_DETAIL_HEDIS_STATS = "documentProcessingDetailHedisStatus",
  UPLOAD_CSV_FILE = "uploadCSV",
  IMPORT_CSV_FILE = "importCSVFile"
}

export enum THEME {
  LIGHT = "light-theme",
  DARK = "dark-theme",
}

export enum TASK_COLLAPSIBLE_COMPONENT_HEADING {
  TASK_DESCRIPTION = "Task Description",
}

export enum DOCUMENT_STATUS {
  FILE_UPLOADED = "FILE UPLOADED",
  // DATA_EXTRACTION_STARTED = "Data Extraction Started",
  FILE_FORMATTING_AND_OCR = "FILE FORMATTING & OCR",
  OCR_COMPLETE = "OCR - COMPLETED",
  CLINICAL_DATA_EXTRACTION_STARTED = "CLINICAL DATA EXTRACTION STARTED",
  SUCCEEDED = "SUCCEEDED",
  COMPLETED = "COMPLETED",
  ERROR = "FAILED",
  ON_HOLD = "ON HOLD",
  COMPLETED_WITH_ERROR = "COMPLETED WITH ERROR",
  PROCESSING = "PROCESSING",
  FETCHING = "FETCHING"
}



export enum FILE_STATUS {
  COMPLETED = "ICD10-MAPPING-COMPLETED",
  ERROR = "ERROR",
}

export enum SERVICE_STATUS {
  READY = "ready",
  PROCESSING = "processing",
  FAILED = "falied",
  UPDATING = "updating",
}

export enum STATUS_CODE {
  FORBIDDEN = 403,
}

export enum OPERATIONS {
  VIEW = "GET",
  CREATE = "POST",
  UPDATE = "PUT",
  DELETE = "DELETE",
}
