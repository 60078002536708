import React, { useEffect, useState } from "react";
import { Button, Modal, DatePicker, Input } from "antd";
import "./hold-filter.scss";
import { Typography } from "antd";
import { FileDoneOutlined } from "@ant-design/icons";
import { documentService } from "../../../../api/document";
import { openNotificationWithIcon } from "../../../../utils";
import dayjs from "dayjs";

const HoldFilter = ({
  fetchDocuments,
}: {
  fetchDocuments: () => void;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dateRange, setDateRange] = useState<IGetDocumentsRequest>({
    fromDate: "",
    toDate: "",
    limit: 0,
    copilot: "hedis",
  });
  const { Title, Text } = Typography;
  const [showButton, setShowButton] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [files, setFiles] = useState(0);
  const [documentData, setDocumentData] = useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleButtonClick = async () => {
    try {
      const response = await documentService.getDocuments(dateRange);
      setFiles(response?.data?.pagination?.totalCount);
      const documentId = response?.data?.data.map((doc: any) => doc.Id);
      setDocumentData(documentId);
    } catch (error) {
      openNotificationWithIcon("", "Error in finding files", "error");
    } finally {
      setShowIcon(true);
    }
  };

  const resetModal = () => {
    setDateRange({
      fromDate: "",
      toDate: "",
      limit: 0,
      copilot: "hedis",
    });
    setShowButton(false);
    setShowIcon(false);
    setFiles(0);
    setDocumentData([]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    resetModal();
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputType: "minPage" | "maxPage"
  ) => {
    const value = parseInt(e.target.value);
    setDateRange((prevState) => ({
      ...prevState,
      [inputType]: isNaN(value) ? null : value,
    }));
  };

  const handleDateChange = (dateString: string, pickerType: "start" | "end") => {
    setDateRange((prevState) => ({
      ...prevState,
      [pickerType === "start" ? "fromDate" : "toDate"]: dateString,
    }));
  };

  const handleHoldFiles = async () => {
    if (documentData.length === 0) {
      openNotificationWithIcon("", "No files to Hold", "error");
      handleCancel();
      return;
    }
    try {
      const response = await documentService.holdProcessing(documentData);
      fetchDocuments();
      if (response.data.message == "OK") {
        openNotificationWithIcon("", "Success On Hold Files", "success");
      }
    } catch (error) {
      openNotificationWithIcon("", "Error in holding files", "error");
    } finally {
      handleCancel();
    }
  };

  useEffect(() => {
    if (dateRange.fromDate && dateRange.toDate) {
      setShowButton(true);
    }
  }, [dateRange]);

  const renderShowButton = (): React.ReactNode => {
    if (showIcon) {
      return (
        <div className="btn-files">
          <div className="file-text">
            <FileDoneOutlined className="file-icon" />
            <Text className="file-typography">{files} Files Found</Text>
          </div>
          <Button className="btn" type="primary" onClick={handleHoldFiles}>
            Hold Selected Files
          </Button>
        </div>
      );
    }
    return (
      <Button className="btn-get-files" type="primary" onClick={handleButtonClick}>
        Get Number of Files
      </Button>
    );
  };

  return (
    <>
      <Button className="outline" onClick={showModal}>
        Hold Date/Time Range
      </Button>
      <Modal className="hold-modal" visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <div className="modal-content">
          <div className="date-picker-content">
            <Title className="date-picker-typography" level={5}>
              Please select date and time range below
            </Title>
            <div className="date-picker-box">
              <DatePicker
                name="start-date"
                className="date-picker"
                placeholder="Start Date and Time"
                showTime
                value={dateRange.fromDate ? dayjs(dateRange.fromDate) : null}
                onChange={(dates, dateString) => handleDateChange(dateString, "start")}
              />
              <DatePicker
                name="end-date"
                className="date-picker"
                placeholder="End Date and Time"
                showTime
                value={dateRange.toDate ? dayjs(dateRange.toDate) : null}
                onChange={(dates, dateString) => handleDateChange(dateString, "end")}
              />
            </div>
          </div>
          <div className="page-range-content">
            <div className="page-range-typography">
              <Title className="typography-one" level={5}>
                Page Number Range(optional)
              </Title>
              <Text className="typography-two" type="secondary">
                eg: 10 to 50 pages in that file or less than 50 pages
              </Text>
            </div>
            <div className="page-range-input">
              <Input
                className="page-range-input-one"
                placeholder="Min. Pages"
                onChange={(e) => handleInputChange(e, "minPage")}
              />
              <Input
                className="page-range-input-two"
                placeholder="Max. Pages"
                onChange={(e) => handleInputChange(e, "maxPage")}
              />
            </div>
          </div>
          {showButton && <div>{renderShowButton()}</div>}
        </div>
      </Modal>
    </>
  );
};

export default HoldFilter;
