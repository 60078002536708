import { COPILOTS_NAME, getCopilotParam } from "../utils";
import { apiClient } from "./apiClient";

import qs from "qs";

const getDocuments = async (reqObj: IGetDocumentsRequest) => {
  const { searchTerm, searchDocumentUUID: documentUUID, ...rest } = reqObj;
  const queryObj: Record<string, any> = { ...rest, documentUUID };

  if (reqObj.copilot === "ANG" || reqObj.copilot === "CCA" || reqObj.copilot === "UM") {
    queryObj["requestId"] = searchTerm;
    const query = qs.stringify(queryObj, { skipNulls: true });
    const res = await apiClient.get(`/requests?${query}`);
    return res.data;
  } else if (reqObj.copilot === "hedis") {
    queryObj["document"] = searchTerm;
    const query = qs.stringify(queryObj, { skipNulls: true });
    const res = await apiClient.get<IGetDocumentsResponse>(`/hedisDocuments/list?${query}`);
    return res;
  } else if (reqObj.copilot === "PCP") {
    //TODO: Add the api to fetch the record from the documents table
    queryObj["document"] = searchTerm;
    const query = qs.stringify(queryObj, { skipNulls: true });
    const res = await apiClient.get(`/pcp/document-process/list?${query}`);
    return res.data;
  } else {
    queryObj["document"] = searchTerm;
    const query = qs.stringify(queryObj, { skipNulls: true });
    const res = await apiClient.get<IGetDocumentsResponse>(`/document-process/list?${query}`);
    return res;
  }
};

const getFields = async () => {
  const res = await apiClient.get(`/priorAuth/keywordMappingKeys`);
  return res;
};

const getDocumentPages = async (documentId: number, limit: number, offset: number) => {
  const res = await apiClient.get<IGetDocumentPagesResponse>(
    `/hedisDocumentPageHistory/status?documentId=${documentId}&limit=${limit}&offset=${offset}`
  );
  return res;
};

const getDocumentMedicalEntitiesByPage = async (documentId: string, pageNumber: string) => {
  const res = await apiClient.get<IGetDocumentMedicalEntitiesByPageResponse>(
    `/search-medical-entities?documentId=${documentId}&pageNumber=${pageNumber}`
  );
  return res;
};

const getHedisStats = async (documentId: number) => {
  const res = await apiClient.get(`/hedisDocumentHistory/hedisstats/${documentId}`);
  return res.data.data;
};

const getAngCCAStats = async (documentId: string) => {
  const res = await apiClient.get(`/requests/status/${documentId}`);
  return res.data.data;
};

const getPegaStats = async (documentId: string) => {
  const res = await apiClient.get(`priorauth/pegastats/${documentId}`);
  return res.data.data;
};

const getKeywordVariations = async (fieldName: string) => {
  const res = await apiClient.get(`/priorAuth/keywordMapping?key=${fieldName}`);
  return res;
};

const saveKeywordVariation = async (reqObj: { data: string }) => {
  const res = await apiClient.put(`/priorauth/keywordMapping`, reqObj);
  return res;
};

const getHedisSummary = async (documentUUID: string) => {
  const res = await apiClient.get(`/document/hedissummary/${documentUUID}?hedis_type=all`);
  return res;
};

const getHedisMeasurement = async (documentUUID: string, pageNumber: number) => {
  const res = await apiClient.get(
    `/document/hedismeasurements/${documentUUID}?hedis_type=all&page_number=${pageNumber}`
  );
  return res;
};

const getClaimsSearchStatus = async (fromDate: string, toDate: string) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const res = await apiClient.get<IGetAPIStatsResponse>(
    '/claims/search-analysis/search-stats' ,
    {
      params: {
        fromDate,
        toDate,
        timezone,
      }
    }
  );
return res.data;
}

const getDocumentApiStats = async (fromDate: string, toDate: string, copilotName?: string) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const copilot = getCopilotParam(copilotName);

  const url = copilotName === COPILOTS_NAME.PCP ? '/pcp/api-stats' : '/overview/api-stats';

    const res = await apiClient.get<IGetAPIStatsResponse>(
      url ,
      {
        params: {
          offset: 0,
          limit: null,
          fromDate,
          toDate,
          copilot,
          timezone,
        }
      }
    );
  return res.data;
};

const getDocumentChartStats = async (fromDate: string, toDate: string, copilotName?: string) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const copilot = getCopilotParam(copilotName);

      const { data } = await apiClient.get<IChartStatsResponse>(
        `/overview/chart-stats`,
        {
          params: {
            fromDate,
            toDate,
            copilot,
            timezone,
          }
        }
      );
  return data;
};

const getPcpDocumentsChartsStats = async (fromDate: string, toDate: string) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const { data } = await apiClient.get<IChartStatsResponse>(
        `/pcp/chart-stats`,
        {
          params: {
            fromDate,
            toDate,
            timezone,
          }
        }
      );
  return data.data;
};

const getDocumentChartStatsCCAandANG = async (
  fromDate: string,
  toDate: string,
  copilotName?: string
) => {
  let copilot = "all";

switch (copilotName) {
  case COPILOTS_NAME.CCA:
    copilot = "CCA";
    break;
  case COPILOTS_NAME.ANG_INSIGHTS:
    copilot = "ANG";
    break;
  case COPILOTS_NAME.UM:
    copilot = "UM";
    break;
  default:
    copilot = "all";
    break;
}


  const query = qs.stringify(
    {
      fromDate,
      toDate,
      copilot,
    },
    { addQueryPrefix: true, skipNulls: true }
  );

  const { data } = await apiClient.get(`/requests/overview/chart-stats${query}`);
  return data;
};

const stopProcessing = async (documentUUID: string[], copilotName?: string) => {
  const res = await apiClient.post(
    `${copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH ? "priorauth" : "hedisDocuments"}/hold`,
    {
      documentUUID,
    }
  );
  return res;
};
const holdProcessing = async (documentId: number[]) => {
  const res = await apiClient.post("hedisDocuments/hold", {
    documentId,
  });
  return res;
};

const restartProcessing = async (documentUUID: string, copilotName?: string) => {
  const res = await apiClient.post(
    `${
      copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH ? "priorauth" : "document-process"
    }/restart/${documentUUID}`
  );
  return res;
};

const deleteDocuments = async (documents: any, copilotName?: string) => {
  const res = await apiClient.post(
    `${copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH ? "priorauth" : "document"}/delete-documents`,
    {
      documents,
    }
  );
  return res;
};

const getHedisExtractionData = async (fromDate: string, toDate: string, type?: string) => {
  const res = await apiClient.get(
    `/document/overview-stats?fromDate=${fromDate}&toDate=${toDate}&type=${type}`
  );
  return res;
};

const getHedisCopilotExtractionData = async (fromDate: string, toDate: string, type?: string) => {
  const res = await apiClient.get(
    `/overview/copilot-overview-stats?fromDate=${fromDate}&toDate=${toDate}&type=${type}`
  );
  return res.data;
};

const getPcpDocumentsHistory =async(documentUUID:string)=>{
  const res = await apiClient.get(`/pcp/documents/history/${documentUUID}`)
  return res.data;
}

const getPcpPagesHistory =async(documentId:string)=>{
  const res = await apiClient.get<any>(`/pcp/documents/page/history/${documentId}`)
  return res.data;
}

export const documentService = {
  getDocuments,
  getAngCCAStats,
  getDocumentPages,
  getDocumentMedicalEntitiesByPage,
  getHedisStats,
  getPegaStats,
  getFields,
  getKeywordVariations,
  saveKeywordVariation,
  getHedisSummary,
  getHedisMeasurement,
  getDocumentApiStats,
  stopProcessing,
  holdProcessing,
  restartProcessing,
  deleteDocuments,
  getDocumentChartStats,
  getHedisExtractionData,
  getHedisCopilotExtractionData,
  getDocumentChartStatsCCAandANG,
  getPcpDocumentsHistory,
  getPcpPagesHistory,
  getPcpDocumentsChartsStats,
  getClaimsSearchStatus
};
