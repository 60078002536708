import React from "react";
import {
  AnGIcon,
  ArrowIcon,
  BMIIcon,
  ChatIcon,
  ECIcon,
  HedisIcon,
  HighRiskIcon,
  HomeIcon,
  MailIcon,
  PAIcon,
  PEGAIcon,
  PIIcon,
  PLOAIcon,
  PPAIcon,
  PriorAuthoIcon,
} from "../../assets/icons";
import { ChartReviewIcon } from "../../assets/icons/ChartReviewIcon";

export interface IApplication {
  id: number;
  link: string;
  icon: JSX.Element;
  title: string;
  description: string;
  isActive: boolean;
}

export const COPILOTS_NAME = {
  CAREGPT_CHATBOT: "Payment Integrity Chat BOT",
  HEDIS_INSIGHTS: "HEDIS Insights",
  PEGA_PRIOR_AUTH: "PEGA Prior Auth",
  ANG_INSIGHTS: "A&G Insights",
  CCA: "CCA",
  PCP:"PCP", 
  CLAIMS: "Claims",
  UM: "UM"
};

export const APPLICATIONS: IApplication[] = [
  {
    id: 1,
    title: "Wizard - AI Assisted Chart Review",
    link: "",
    icon: <ChartReviewIcon />,
    description:
      "Summarize and accelerate chart reviews of patient and members and contextualize to task",
    isActive: true,
  },
  {
    id: 2,
    title: "Patient Level Outcome Analysis",
    link: "",
    icon: <PLOAIcon />,
    description: "Analyze and predict outcomes from historic data, and use",
    isActive: true,
  },
  {
    id: 3,
    title: "Pixel  - AI Powered Patient Matching",
    link: "",
    icon: <PriorAuthoIcon />,
    description:
      "Match patients to Clinical Trials Eligibility Criteria using powerful Clinical LLMs",
    isActive: true,
  },
  {
    id: 4,
    title: "Analysis of High Risk population",
    link: "",
    icon: <HighRiskIcon />,
    description: "Identify and Stratify risk from clinical, financial and other data sources",
    isActive: true,
  },
  {
    id: 5,
    title: "Clinical Trial Protocol Insights Digitization",
    link: "",
    icon: <ECIcon />,
    description:
      "Analyze & simplify IE criteria and understand burden to patient, physician and study timelines",
    isActive: false,
  },
  {
    id: 6,
    title: "Protocol Analysis",
    link: "",
    icon: <PAIcon />,
    description: "Therapeutic area insights from publicly available and private data sources",
    isActive: false,
  },
  {
    id: 7,
    title: "Clinical Trial Patient Engagement Co-pilot",
    link: "",
    icon: <PPAIcon />,
    description:
      "Contextual Conversational bot for patient engagement to accelerate pre-screening and enrollment",
    isActive: false,
  },
  {
    id: 8,
    title: "BioMedical Insights",
    link: "",
    icon: <BMIIcon />,
    description: "Therapeutic area insights from publicly available and private data sources",
    isActive: false,
  },
];

export const Copilots: IApplication[] = [
  {
    id: 1,
    title: COPILOTS_NAME.CAREGPT_CHATBOT,
    link: "",
    icon: <PIIcon width={60} height={60} fill="#5F3CAB" />,
    description: "Multi document Q & A BOT for Payment Integrity team",
    isActive: true,
  },
  {
    id: 2,
    title: COPILOTS_NAME.HEDIS_INSIGHTS,
    link: "",
    icon: <HedisIcon />,
    description: "Deriving HEDIS insights from clinical data",
    isActive: true,
  },
  {
    id: 3,
    title: COPILOTS_NAME.PEGA_PRIOR_AUTH,
    link: "",
    icon: <PEGAIcon />,
    description: "Automating Prior Auth case creation from incoming faxes",
    isActive: true,
  },
  {
    id: 4,
    title: COPILOTS_NAME.CCA,
    link: "",
    icon: <AnGIcon />,
    description: "Customer Contract Analysis",
    isActive: true,
  },
  {
    id: 5,
    title: COPILOTS_NAME.PCP,
    link: "",
    icon: <AnGIcon />,
    description: "Extracting Provider & Patient Information from A&G Fax documents",
    isActive: true,
  },
  {
    id: 6,
    title: COPILOTS_NAME.ANG_INSIGHTS,
    link: "",
    icon: <AnGIcon />,
    description: "Extracting Provider & Patient Information from A&G Fax documents",
    isActive: true,
  },
  {
    id: 7, 
    title: COPILOTS_NAME.CLAIMS,
    link: "",
    icon: <AnGIcon />,
    description: "Improve efficiency of the Claims Examiners by guiding through Claims SOP documents",
    isActive: true,
  },
  {
    id: 8, 
    title: COPILOTS_NAME.UM,
    link: "",
    icon: <AnGIcon />,
    description: "Extracting Provider & Patient Information from A&G Fax documents",
    isActive: true,
  }
];

export const servicesHealth = {
  data: [
    {
      serviceName: "RBAC",
      description: "Service handling user access permissions and role management.",
      statusCode: 200,
      message: "UP and RUNNING - The service is available and working.",
      availability: "100%",
      coPilots: ["Payment Integrity Chat BOT", "HEDIS Insights", "PEGA Prior Auth"],
    },
    {
      serviceName: "AUTH",
      description: "Service handling user authentication and authorization.",
      statusCode: 200,
      message: "UP and RUNNING - The service is available and working.",
      availability: "100%",
      coPilots: ["Payment Integrity Chat BOT", "HEDIS Insights", "PEGA Prior Auth"],
    },
    {
      serviceName: "Wizard",
      description: "Service handling all the operations for Wizard app.",
      statusCode: 200,
      message: "UP and RUNNING - The service is available and working.",
      availability: "90%",
      coPilots: ["HEDIS Insights", "PEGA Prior Auth"],
    },
    {
      serviceName: "HEDIS",
      description: "Service Deriving HEDIS Insights",
      statusCode: 200,
      message: "UP and RUNNING - The service is available and working.",
      availability: "100%",
      coPilots: ["HEDIS Insights"],
    },
    {
      serviceName: "Payment Integrity",
      description: "Service handling Payment Integrity ML operations",
      statusCode: 200,
      message: "UP and RUNNING - The service is available and working.",
      availability: "100%",
      coPilots: ["Payment Integrity Chat BOT"],
    },
  ],
  transaction_id: "e9522468-3016-41d7-ac4d-cd1581bb6be0",
};

export const tokenBillingResponseDateWise = {
  data: {
    message: "OK",
    data: [
      {
        date: "2023-05-08",
        token_count: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 974,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 4931,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 2628,
          },
        ],
        open_ai_tokens: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 345,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 1234,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 2345,
          },
        ],
        ocr_calls: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 237,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 124,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 235,
          },
        ],
        documents_processed: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 150,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 65,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 120,
          },
        ],
        questions_answered: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 35,
          },
        ],
        feedbacks: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 4,
          },
        ],
      },
      {
        date: "2023-05-15",
        token_count: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 1200,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 3500,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 2000,
          },
        ],
        open_ai_tokens: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 400,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 1500,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 800,
          },
        ],
        ocr_calls: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 180,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 90,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 150,
          },
        ],
        documents_processed: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 100,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 50,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 80,
          },
        ],
        questions_answered: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 25,
          },
        ],
        feedbacks: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 8,
          },
        ],
      },
      {
        date: "2023-06-01",
        token_count: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 2377,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 2639,
          },
        ],
        open_ai_tokens: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 345,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 1234,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 2345,
          },
        ],
        ocr_calls: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 237,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 124,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 235,
          },
        ],
        documents_processed: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 150,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 65,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 120,
          },
        ],
        questions_answered: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 35,
          },
        ],
        feedbacks: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 6,
          },
        ],
      },
      {
        date: "2023-06-10",
        token_count: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 1900,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 2100,
          },
        ],
        open_ai_tokens: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 400,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 1800,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 1000,
          },
        ],
        ocr_calls: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 150,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 80,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 130,
          },
        ],
        documents_processed: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 80,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 35,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 60,
          },
        ],
        questions_answered: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 15,
          },
        ],
        feedbacks: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 10,
          },
        ],
      },
      {
        date: "2023-07-05",
        token_count: [
          {
            service_name: "HEDIS Insights",
            token_count: 9212,
          },
        ],
        open_ai_tokens: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 345,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 1234,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 2345,
          },
        ],
        ocr_calls: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 237,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 124,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 235,
          },
        ],
        documents_processed: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 150,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 65,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 120,
          },
        ],
        questions_answered: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 35,
          },
        ],
        feedbacks: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 25,
          },
        ],
      },
      {
        date: "2023-07-15",
        token_count: [
          {
            service_name: "HEDIS Insights",
            token_count: 8000,
          },
        ],
        open_ai_tokens: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 345,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 1234,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 2345,
          },
        ],
        ocr_calls: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 237,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 124,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 235,
          },
        ],
        documents_processed: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 150,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 65,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 120,
          },
        ],
        questions_answered: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 35,
          },
        ],
        feedbacks: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 30,
          },
        ],
      },
      {
        date: "2023-08-01",
        token_count: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 1925,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 5143,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 4866,
          },
        ],
        open_ai_tokens: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 345,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 1234,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 2345,
          },
        ],
        ocr_calls: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 237,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 124,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 235,
          },
        ],
        documents_processed: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 150,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 65,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 120,
          },
        ],
        questions_answered: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 35,
          },
        ],
        feedbacks: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 42,
          },
        ],
      },
      {
        date: "2023-08-10",
        token_count: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 1925,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 5143,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 4866,
          },
        ],
        open_ai_tokens: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 345,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 1234,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 2345,
          },
        ],
        ocr_calls: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 237,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 124,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 235,
          },
        ],
        documents_processed: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 150,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 65,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 120,
          },
        ],
        questions_answered: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 35,
          },
        ],
        feedbacks: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 42,
          },
        ],
      },
      {
        date: "2023-09-03",
        token_count: [
          {
            service_name: "HEDIS Insights",
            token_count: 485,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 13203,
          },
          {
            service_name: "PEGA Prior Auth",
            token_count: 4574,
          },
        ],
        open_ai_tokens: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 345,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 1234,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 2345,
          },
        ],
        ocr_calls: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 237,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 124,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 235,
          },
        ],
        documents_processed: [
          {
            service_name: "PEGA Prior Auth",
            token_count: 150,
          },
          {
            service_name: "HEDIS Insights",
            token_count: 65,
          },
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 120,
          },
        ],
        questions_answered: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 35,
          },
        ],
        feedbacks: [
          {
            service_name: "Payment Integrity Chat BOT",
            token_count: 17,
          },
        ],
      },
    ],
  },
};

export const angTableSampleData = {
  data: {
    message: "OK",
    data: [
      {
        Id: 2130,
        DocumentUUID: "64972076-3D38-45DD-91F2-39BABB9BE79F",
        FileName: "Provider-Claim-Information-Form-FNAL_508c - AZ_mocked.pdf",
        createdAt: "2024-01-25T08:36:51.720Z",
        updatedAt: "2024-01-25T08:36:51.720Z",
        DocumentProcessStatuses: [
          {
            Id: 0,
            DocumentProcessId: 0,
            Status: "SUCCEEDED",
            createdAt: "2024-01-25T08:36:51.720Z",
            updatedAt: "2024-01-25T08:36:51.720Z",
            message: "",
          },
        ],
      },
      {
        Id: 2129,
        DocumentUUID: "A74BD24D-E573-41E4-9D04-1C952766FE71",
        FileName: "WI_ProviderAppealForm WI_mocked.pdf",
        createdAt: "2024-01-25T08:36:27.867Z",
        updatedAt: "2024-01-25T08:36:27.867Z",
        DocumentProcessStatuses: [
          {
            Id: 0,
            DocumentProcessId: 0,
            Status: "SUCCEEDED",
            createdAt: "2024-01-25T08:36:27.867Z",
            updatedAt: "2024-01-25T08:36:27.867Z",
            message: "",
          },
        ],
      },
      {
        Id: 2128,
        DocumentUUID: "8AEEBD87-F043-4039-9280-F4556FBB0A1D",
        FileName: "NVClaims_Reconsideration_Request_Form_R_mocked.pdf",
        createdAt: "2024-01-25T08:36:13.440Z",
        updatedAt: "2024-01-25T08:36:13.440Z",
        DocumentProcessStatuses: [
          {
            Id: 0,
            DocumentProcessId: 0,
            Status: "SUCCEEDED",
            createdAt: "2024-01-25T08:36:13.440Z",
            updatedAt: "2024-01-25T08:36:13.440Z",
            message: "",
          },
        ],
      },
      {
        Id: 2127,
        DocumentUUID: "F43BB812-4F04-4CE7-8A7E-F9938F907128",
        FileName: "provider_appeal_request_form UT_mocked.pdf",
        createdAt: "2024-01-25T08:35:55.473Z",
        updatedAt: "2024-01-25T08:35:55.473Z",
        DocumentProcessStatuses: [
          {
            Id: 0,
            DocumentProcessId: 0,
            Status: "SUCCEEDED",
            createdAt: "2024-01-25T08:35:55.473Z",
            updatedAt: "2024-01-25T08:35:55.473Z",
            message: "",
          },
        ],
      },
      {
        Id: 2126,
        DocumentUUID: "A14C7745-0E28-4381-9CC2-0C7198B6F548",
        FileName: "Provider-Appeal-Dispute-Form-Updated-Oct-2023 FL_mocked.pdf",
        createdAt: "2024-01-25T08:35:11.853Z",
        updatedAt: "2024-01-25T08:35:11.853Z",
        DocumentProcessStatuses: [
          {
            Id: 0,
            DocumentProcessId: 0,
            Status: "SUCCEEDED",
            createdAt: "2024-01-25T08:35:11.853Z",
            updatedAt: "2024-01-25T08:35:11.853Z",
            message: "",
          },
        ],
      },
      {
        Id: 2125,
        DocumentUUID: "8A33B995-6582-4210-9CB8-0560FB40219D",
        FileName: "VA-ALL-MF-11432-22-B-Appeal-Request-Form VA_mocked.pdf",
        createdAt: "2024-01-25T08:34:35.520Z",
        updatedAt: "2024-01-25T08:34:35.520Z",
        DocumentProcessStatuses: [
          {
            Id: 0,
            DocumentProcessId: 0,
            Status: "SUCCEEDED",
            createdAt: "2024-01-25T08:34:35.520Z",
            updatedAt: "2024-01-25T08:34:35.520Z",
            message: "",
          },
        ],
      },
      {
        Id: 2124,
        DocumentUUID: "11FBB3E9-1050-4D35-AD4A-6AAE0D42C47F",
        FileName: "AnG3.pdf",
        createdAt: "2024-01-25T08:34:16.767Z",
        updatedAt: "2024-01-25T08:34:16.767Z",
        DocumentProcessStatuses: [
          {
            Id: 0,
            DocumentProcessId: 0,
            Status: "SUCCEEDED",
            createdAt: "2024-01-25T08:34:16.767Z",
            updatedAt: "2024-01-25T08:34:16.767Z",
            message: "",
          },
        ],
      },
      {
        Id: 2123,
        DocumentUUID: "ABF7C412-0362-4ACF-8298-4F58DC35166B",
        FileName: "AnG2.pdf",
        createdAt: "2024-01-25T08:33:22.143Z",
        updatedAt: "2024-01-25T08:33:22.143Z",
        DocumentProcessStatuses: [
          {
            Id: 0,
            DocumentProcessId: 0,
            Status: "SUCCEEDED",
            createdAt: "2024-01-25T08:33:22.143Z",
            updatedAt: "2024-01-25T08:33:22.143Z",
            message: "",
          },
        ],
      },
      {
        Id: 2122,
        DocumentUUID: "4EE5F52D-6488-4C23-ADCB-33DC5F2DBA89",
        FileName: "AnG1.pdf",
        createdAt: "2024-01-25T05:31:02.680Z",
        updatedAt: "2024-01-25T05:31:02.680Z",
        DocumentProcessStatuses: [
          {
            Id: 0,
            DocumentProcessId: 0,
            Status: "SUCCEEDED",
            createdAt: "2024-01-25T05:31:02.680Z",
            updatedAt: "2024-01-25T05:31:02.680Z",
            message: "",
          },
        ],
      },
    ],
    pagination: {
      totalCount: 9,
      offset: 0,
      limit: 20,
    },
    extractionData: {
      totalDocsWithData: 1,
      totalFields: 12,
      totalExtractedFields: 6,
      totalMissingFields: 6,
      totalSrfPageFound: 1,

      fieldWiseExtractionData: {
        acuity: 0,
        dateOfBirth: 0,
        diagnoses: 1,
        memberID: 0,
        memberName: 0,
        placeOfService: 0,
        procedures: 1,
        requestingProviderName: 1,
        requestingProviderNPI: 1,
        servicingProviderName: 1,
        servicingProviderNPI: 1,
        typeOfService: 0,
      },
    },
    transaction_id: "b6f7007a-9a76-4df7-9c79-8643d012d52f",
  },
};

export const AnGFilesSummaryData = [
  {
    file_name: "AnG1.pdf",
    patient_data: {
      "Member ID": "45122422",
      "Patient Name": "John Doe",
      "Member Date of Birth/DOB": "Information not found",
      "Claim ID": "4457745513A",
      "Authorization ID": "Information not found",
    },
    provider_data: {
      "Claim ID": "4457745513A",
      "Authorization ID": "Information not found",
      "Provider Name": "Harry Ken",
      "Provider NPI Number": "452145256",
      "Provider Tax ID": "14521452421",
      "Provider Phone Number": "(878) 455 - 123",
      "Provider Fax Number": "Information not found",
      "Provider Email ID": "Information not found",
    },
  },
  {
    file_name: "AnG2.pdf",
    patient_data: {
      "Member ID": "78854212",
      "Patient Name": "Ken Adams",
      "Member Date of Birth/DOB": "01/01/1999",
      "Claim ID": "22307254825A1",
      "Authorization ID": "55412311325",
    },
    provider_data: {
      "Claim ID": "22307254825A1",
      "Authorization ID": "55412311325",
      "Provider Name": "Elmhurst Hospital Center",
      "Provider NPI Number": "89566332523",
      "Provider Tax ID": "13-2655001",
      "Provider Phone Number": "833-780-1780",
      "Provider Fax Number": "877-908-1836",
      "Provider Email ID": "Information not found",
    },
  },
  {
    file_name: "AnG3.pdf",
    patient_data: {
      "Member ID": "78994552",
      "Patient Name": "John Freddy",
      "Member Date of Birth/DOB": "Information not found",
      "Claim ID": "545124785",
      "Authorization ID": "Information not found",
    },
    provider_data: {
      "Claim ID": "545124785",
      "Authorization ID": "Information not found",
      "Provider Name": "Jenkins Hospital",
      "Provider NPI Number": "456123567",
      "Provider Tax ID": "789456123",
      "Provider Phone Number": "833-815-0637",
      "Provider Fax Number": "888-974-0241",
      "Provider Email ID": "Information not found",
    },
  },
  {
    file_name: "NVClaims_Reconsideration_Request_Form_R_mocked.pdf",
    patient_data: {
      "Member ID": "897521X",
      "Patient Name": "John Doe",
      "Member Date of Birth/DOB": "15 / 10 / 1999",
      "Claim ID": "2230725482A2",
      "Authorization ID": "Information not found",
    },
    provider_data: {
      "Claim ID": "2230725482A2",
      "Authorization ID": "Information not found",
      "Provider Name": "Mike, Harper",
      "Provider NPI Number": "8956231452",
      "Provider Tax ID": "ABC12345",
      "Provider Phone Number": "( 470) 555 -0000",
      "Provider Fax Number": "( 877) 908 -1111",
      "Provider Email ID": "Information not found",
    },
  },
  {
    file_name: "provider_appeal_request_form UT_mocked.pdf",
    patient_data: {
      "Member ID": "85212345AA",
      "Patient Name": "David, Villa",
      "Member Date of Birth/DOB": "12/11/1995",
      "Claim ID": "22307254825A1",
      "Authorization ID": "Information not found",
    },
    provider_data: {
      "Claim ID": "22307254825A1",
      "Authorization ID": "Information not found",
      "Provider Name": "Goerge, Harry",
      "Provider NPI Number": "7452365894",
      "Provider Tax ID": "Information not found",
      "Provider Phone Number": "(555) 555 - 555",
      "Provider Fax Number": "(000) 000 - 000",
      "Provider Email ID": "Information not found",
    },
  },
  {
    file_name: "Provider-Appeal-Dispute-Form-Updated-Oct-2023 FL_mocked.pdf",
    patient_data: {
      "Member ID": "B4587412X",
      "Patient Name": "Ken, Adams",
      "Member Date of Birth/DOB": "02/05/1997",
      "Claim ID": "88712567842A2",
      "Authorization ID": "Information not found",
    },
    provider_data: {
      "Claim ID": "88712567842A2",
      "Authorization ID": "Information not found",
      "Provider Name": "AMS Healthcare Hospitals",
      "Provider NPI Number": "5263147854",
      "Provider Tax ID": "Information not found",
      "Provider Phone Number": "(888) 111 - 000",
      "Provider Fax Number": "(877) 553-6504",
      "Provider Email ID": "MFL_ProviderAppeals@Molinahealthcare.com",
    },
  },
  {
    file_name: "Provider-Claim-Information-Form-FNAL_508c - AZ_mocked.pdf",
    patient_data: {
      "Member ID": "Information not found",
      "Patient Name": "Information not found",
      "Member Date of Birth/DOB": "Information not found",
      "Claim ID": "Information not found",
      "Authorization ID": "PUM456123",
    },
    provider_data: {
      "Claim ID": "Information not found",
      "Authorization ID": "PUM456123",
      "Provider Name": "Larry James",
      "Provider NPI Number": "7412369874",
      "Provider Tax ID": "5522552255",
      "Provider Phone Number": "Information not found",
      "Provider Fax Number": "Information not found",
      "Provider Email ID": "info@orchidhealthcare.com",
    },
  },
  {
    file_name: "VA-ALL-MF-11432-22-B-Appeal-Request-Form VA_mocked.pdf",
    patient_data: {
      "Member ID": "8855447A",
      "Patient Name": "Joseph Saj",
      "Member Date of Birth/DOB": "08/12/1996",
      "Claim ID": "223311447744A2",
      "Authorization ID": "Information not found",
    },
    provider_data: {
      "Claim ID": "223311447744A2",
      "Authorization ID": "Information not found",
      "Provider Name": "Ken, Adams",
      "Provider NPI Number": "Information not found",
      "Provider Tax ID": "Information not found",
      "Provider Phone Number": "(888) 555 - 000",
      "Provider Fax Number": "1-866-325-9157",
      "Provider Email ID": "Information not found",
    },
  },
  {
    file_name: "WI_ProviderAppealForm WI_mocked.pdf",
    patient_data: {
      "Member ID": "55441122",
      "Patient Name": "Fernandez, Harry",
      "Member Date of Birth/DOB": "09/12/1985",
      "Claim ID": "7744112233A3",
      "Authorization ID": "Information not found",
    },
    provider_data: {
      "Claim ID": "7744112233A3",
      "Authorization ID": "Information not found",
      "Provider Name": "John, Doe",
      "Provider NPI Number": "884411223366",
      "Provider Tax ID": "441144778899",
      "Provider Phone Number": "(999) 000 - 000",
      "Provider Fax Number": "Information not found",
      "Provider Email ID": "info@universalhealthcare.com",
    },
  },
];
