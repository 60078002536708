import React, { useEffect, useState } from "react";
import { DatePicker, Loading } from "../../components";
import { useFetchDocuments } from "../../hooks";
import { RangeValue } from "rc-picker/lib/interface";
import moment, { Moment } from "moment";
import { rangePresets } from "../copilot-details/copilots-details-container/helper";
import DonutChart from "./graphs/DonutChart";
import { ProcessingTrend } from "./graphs/ProcessingTrend";
import ApiCallStatistics from "./graphs/APICallStatistics";
import { APICallTrend } from "./graphs/APICallTrend";
import "./graphs/graphs.scss";
import { calculateProcessed, COPILOTS_NAME } from "../../utils";
import { ExtractionData } from "./graphs/ExtractionData";
import { documentService } from "../../api/document";
import { RootState, setSelectedDateRange } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { CLAIMS_API_STATS_DATA, CLAIMS_CHARTS_DATA } from "../copilot-details/copilots-details-container/constants";

interface SummaryDataAndGraphsProps {
  copilotName?: string;
  overallSummary?: boolean;
  hideExtractionData?: boolean;
}

interface DocStatsType {
  totalDocsWithData: number;
  totalFields: number;
  totalExtractedFields: number;
  totalMissingFields: number;
  totalSrfPageFound: number;
  totalSuccess: number;
  totalFailed: number;
  totalInProgress: number;
  dateWiseExtractionData: { date: string; processed: number }[];
}

type TokenData = {
  date: string;
  token_count: {
    service_name: string;
    moduleName: string;
    token_count: number;
  }[];
}[];

interface ProcessingTrendProps {
  data: { date: string; processed: number }[];
}

export const SummaryDataAndGraphs = ({
  copilotName,
  overallSummary,
  hideExtractionData
}: SummaryDataAndGraphsProps) => {
  const { RangePicker } = DatePicker;
  const {
    setFromDate,
    setToDate,
    defaultFromDate,
    defaultToDate,
    searchTerm,
    setSearchTerm,
    defaultOverviewFromDate,
    overviewFromDate,
    setOverviewFromDate,
  } = useFetchDocuments();
  const dispatch = useDispatch();
  const [docStats, setDocStats] = useState<ChartStatsData[]>([]);
  const [chartData, setChartData] = useState({
    successes: 0,
    failures: 0,
  });
  // const [tokenCount, setTokenCount] = useState<TokenData | null>(null);
  const [tokenCount, setTokenCount] = useState<any>(null);
  const [extractionDataCount, setExtractionDataCount] = useState<ProcessingTrendProps | null>(null);
  const [isLoadingChartStats, setIsLoadingChartStats] = useState(false);
  const [isLoadingApiStats, setIsLoadingApiStats] = useState(false);

  const { fromDate: selectedFromDate, toDate: selectedToDate } = useSelector(
    (state: RootState) => state.ui.selectedDateRange
  );

  const onRangeChange = (dates: RangeValue<Moment>, dateStrings: string[]) => {
    if (dates) {
      dispatch(
        setSelectedDateRange({
          fromDate: dates[0]?.toString() ?? null,
          toDate: dates[1]?.toString() ?? null,
        })
      );
    } else {
      dispatch(
        setSelectedDateRange({
          fromDate: defaultOverviewFromDate?.toString() ?? null,
          toDate: defaultToDate?.toString() ?? null,
        })
      );
    }
  };

  const fetchApiStats = async () => {
    setIsLoadingApiStats(true);
    let apiData: any;
  
    try {
      switch (copilotName) {
        case COPILOTS_NAME.CLAIMS:
          apiData = await documentService.getClaimsSearchStatus(
            moment(selectedFromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            moment(selectedToDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
          );
          break;
        
        default:
          apiData = await documentService.getDocumentApiStats(
            moment(selectedFromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            moment(selectedToDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            copilotName
          );
      }
      if (copilotName === COPILOTS_NAME.CLAIMS) {
        const data = Object.entries(apiData.data).map(([date, entry]:any) => {
          return ({
          date,
          token_count: entry,
        })});
        const chartStatsData: ChartStatsData[] = calculateProcessed(apiData.data);
        setDocStats(chartStatsData);
        setTokenCount(data)
      } else if ((copilotName && 
          [COPILOTS_NAME.HEDIS_INSIGHTS, COPILOTS_NAME.PEGA_PRIOR_AUTH, COPILOTS_NAME.CAREGPT_CHATBOT,].includes(copilotName))
          || !copilotName
        ) {
        const data = Object.entries(apiData.data).map(([date, token_count]) => ({
          date,
          token_count,
        }));
        setTokenCount(data);
      } else if (apiData?.data?.data) {
        const data = Object.entries(apiData.data.data).map(([date, token_count]) => ({
          date,
          token_count,
        }));
        setTokenCount(data);
      } else {
        setTokenCount([]);
      }
    } catch (error) {
      console.error("Error fetching API stats:", error);
      setTokenCount([]);
    } finally {
      setIsLoadingApiStats(false);
    }
  };


  const fetchChartStats = async () => {
    const filterDateData = {
      fromDate: moment(selectedFromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      toDate: moment(selectedToDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
    }
    if (copilotName && [COPILOTS_NAME.CCA, COPILOTS_NAME.ANG_INSIGHTS, COPILOTS_NAME.UM].includes(copilotName)) {
      setIsLoadingChartStats(true);
      try {
        
        const response = await documentService.getDocumentChartStatsCCAandANG(
          filterDateData.fromDate,
          filterDateData.toDate,
          copilotName
        );

        const chartStats = { successes: 0, failures: 0 };
        response.data.forEach((dayStat: ChartStatsData) => {
          if (dayStat.status === "SUCCEEDED") {
            chartStats.successes += dayStat.processed;
          } else if (dayStat.status === "FAILED") {
            chartStats.failures += dayStat.processed;
          }
        });
        setDocStats(response.data);
        setChartData(chartStats);
      } catch (err: any) {
        console.error("Error fetching chart stats:", err);
      } finally {
        setIsLoadingChartStats(false);
      }
      return;
    }

    setIsLoadingChartStats(true);
    try {
      let response:any;

      if( copilotName === COPILOTS_NAME.PCP ){
        
        response = await documentService.getPcpDocumentsChartsStats(
          filterDateData.fromDate,
          filterDateData.toDate,
        );
      }
      else
       response = await documentService.getDocumentChartStats(
        filterDateData.fromDate,
        filterDateData.toDate,
        copilotName
      );
      setDocStats(response.data);

      const chartStats = { successes: 0, failures: 0 };
      response.data.forEach((dayStat: ChartStatsData) => {
        const upperStatus = dayStat.status.toUpperCase();
        const isSuccess = ["SUCCEEDED", "SUCCESS", "COMPLETED"].includes(upperStatus);
        const isFailed = ["FAILED", "FAILURE"].includes(upperStatus);
        
        if (isSuccess) {
          chartStats.successes += dayStat.processed;
        } else if (isFailed) {
          chartStats.failures += dayStat.processed;
        }
      });

      setChartData(chartStats);
    } catch (err: any) {
      console.error("Error fetching chart stats:", err);
    } finally {
      setIsLoadingChartStats(false);
    }
  };

  useEffect(() => {
    fetchApiStats();
    if(copilotName!== COPILOTS_NAME.CLAIMS){
      fetchChartStats();
    }
  }, [selectedFromDate, selectedToDate]);

  const renderDatePicker = () => (
    <div className="service-datePicker">
      <p className="service-datePicker-title">Select Date Range:</p>
      <RangePicker
        defaultValue={[
          selectedFromDate ? moment(selectedFromDate) : null,
          selectedToDate ? moment(selectedToDate) : null,
        ]}
        presets={rangePresets}
        onChange={onRangeChange}
        className="range-picker"
        allowClear={false}
        disabledDate={(current) => {
          return current && current.valueOf() > moment().endOf("day").valueOf();
        }}
      />
    </div>
  );

  const renderDonutChart = () => (
    <div className="donut-container">
      <h3 className="summary-title">
        {copilotName === "CCA" || copilotName === "A&G Insights"|| copilotName === "UM"
          ? "Requests Processed"
          : "Documents Processed"}
      </h3>
      <p>
        {copilotName === "CCA" || copilotName === "A&G Insights"|| copilotName === "UM"
          ? "Total requests processed with status success, failed"
          : "Total documents processed with status success, failed"}
      </p>
      <DonutChart
        donutData={[
          { type: "Success", value: chartData.successes },
          { type: "Failed", value: chartData.failures },
        ]}
      />
    </div>
  );

  const renderProcessingTrend = () => (
    <div className="processing-trend">
      <ProcessingTrend
        data={docStats}
        fromDate={defaultOverviewFromDate}
        toDate={defaultToDate}
        copilotName={copilotName}
      />
    </div>
  );


  const renderApiCallTreds = () =>(
    <div style={{ flex: 1 }}>
      {tokenCount && <APICallTrend data={{ tokenData: tokenCount }} />}
    </div>
  )

  const renderApiCallStatistics = ()=>(
    <div style={{ flex: 1 }}>
        {tokenCount && <ApiCallStatistics tokenCount={tokenCount} copilotName={copilotName} />}
      </div>
  )

  const renderApiCallsAndTrends = () => (
    <div className={`summary-container other-copilots`}>
      {renderApiCallStatistics()}
      {renderApiCallTreds()}
    </div>
  );

  const renderLoading = (message: string) => (
    <div style={{ height: "200px", width: "50%" }}>
      <Loading tip={message} />
    </div>
  );

  const renderExtractionData = () => (
    <ExtractionData
      copilotName={copilotName || ""}
      overviewFromDate={
        selectedFromDate
          ? moment(selectedFromDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
      }
      toDate={
        selectedToDate
          ? moment(selectedToDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
      }
      chartData={chartData}
    />
  );
 
  const renderClaimsInsights = () => {
    if(isLoadingChartStats || isLoadingApiStats) {
      return <div className="flex">
        {renderLoading("Loading search stats...")}
        {renderLoading("Loading search trends...")}
      </div>
    }
    return(
    <div className="flex gp jcse">
      {renderApiCallStatistics()}
      {renderProcessingTrend()}
    </div>
  )}

  const renderCopilotInsights = () => {
    
    if( isLoadingChartStats ) {
      return renderLoading("Loading documents stats...");
    }
    return (
      <>
        {(copilotName === COPILOTS_NAME.PEGA_PRIOR_AUTH ) && (!hideExtractionData) ? (
            renderExtractionData()
          ) : (
            <></>
          )}
          { copilotName === COPILOTS_NAME.CLAIMS ? renderClaimsInsights() :
             (
              <div style={{ display: "flex", gap: "20px", justifyContent: "space-evenly" }}>
                      {isLoadingChartStats ?
                        <div className="flex">
                          {renderLoading("Loading documents stats...")}
                          </div>
                        : docStats &&(
                        <div
                        className={`summary-container ${
                          copilotName === "CCA" || copilotName === "A&G Insights" || copilotName === "UM"
                            ? "row-layout"
                            : "other-copilots"
                        }`}
                      >
                        {renderDonutChart()}
                        {renderProcessingTrend()}
                      </div>)}
                    
                {!(copilotName === "CCA" || copilotName === "A&G Insights" || copilotName === "UM" || copilotName == COPILOTS_NAME.CLAIMS ) &&
                  (isLoadingApiStats ? renderLoading("Loading API stats...") : renderApiCallsAndTrends())}
              </div>
          )
        }
        </>
  )}

  

  return (
    <div className="wrapper">
      <div className="service-status">
        <h4 className="service-status-title">Insights</h4>
        <div className="service-horizontal"></div>
        {renderDatePicker()}
      </div>
      {renderCopilotInsights()}
    </div>
  );
};
