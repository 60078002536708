import moment, { Moment } from "moment";
import { PresetDate, EventValue, RangeValue } from "rc-picker/lib/interface";
import { DOCUMENT_STATUS } from "../../../utils";
import './copilots-details.scss';
import { STATUS_COLORS } from "./constants";

export const getLastWeekDates = () => {
  const startDateOfCurrentWeek = moment().startOf("week");
  const endDateOfLastWeek = startDateOfCurrentWeek.clone().subtract(1, "day");
  const startDateOfLastWeek = endDateOfLastWeek.clone().subtract(6, "days");
  return [startDateOfLastWeek, endDateOfLastWeek] as [EventValue<Moment>, EventValue<Moment>];
};

export const getLastMonthDates = () => {
  const startDateOfCurrentMonth = moment().startOf("month");
  const endDateOfLastMonth = startDateOfCurrentMonth.clone().subtract(1, "day");
  const startDateOfLastMonth = endDateOfLastMonth.clone().startOf("month");
  return [startDateOfLastMonth, endDateOfLastMonth] as [EventValue<Moment>, EventValue<Moment>];
};

export const rangePresets: PresetDate<[EventValue<Moment>, EventValue<Moment>]>[] = [
  {
    label: "Today",
    value: [moment().startOf("day"), moment().endOf("day")],
  },
  {
    label: "Last 7 Days",
    value: [moment().add(-7, "d"), moment()],
  },
  {
    label: "Last Week",
    value: getLastWeekDates(),
  },
  {
    label: "Last 30 Days",
    value: [moment().add(-30, "d"), moment()],
  },
  {
    label: "Last Month",
    value: getLastMonthDates(),
  },
];

export const getClass = (value: string) => {
  switch (value.toUpperCase()) {
    case DOCUMENT_STATUS.COMPLETED:
    case DOCUMENT_STATUS.SUCCEEDED:
      return STATUS_COLORS.COMPLETED;
    case DOCUMENT_STATUS.ERROR:
      return STATUS_COLORS.ERROR;
    case DOCUMENT_STATUS.ON_HOLD:
      return STATUS_COLORS.ON_HOLD;
    case DOCUMENT_STATUS.PROCESSING:
      return STATUS_COLORS.PROCESSING;
    case DOCUMENT_STATUS.FETCHING:
      return STATUS_COLORS.FETCHING;
    default:
      return STATUS_COLORS.DEFAULT;
  }
};




export const getPCPStatusClass = (value: string) => {
  switch (value) {
    case DOCUMENT_STATUS.COMPLETED:
      return "success";
    case DOCUMENT_STATUS.SUCCEEDED:
      return "success";
    case DOCUMENT_STATUS.ERROR:
      return "error";
    case DOCUMENT_STATUS.ON_HOLD:
      return "blue";
    case DOCUMENT_STATUS.PROCESSING:
      return "warning";
    default:
      return "";
  }
};

export const getClaimsStatusClass = (status: string, type: 'SESSION' | 'SEARCH') => {
  const upperStatus = status.toUpperCase();
  
  if (type === 'SESSION') {
    switch (upperStatus) {
      case 'IN_REVIEW':
        return STATUS_COLORS.IN_REVIEW; // Blue
      case 'PROCESS_COMPLETE':
        return STATUS_COLORS.PROCESS_COMPLETE; // Green
      case 'NOT_COMPLETE':
        return STATUS_COLORS.NOT_COMPLETE; // Red
      default:
        return STATUS_COLORS.DEFAULT;
    }
  }

  if (type === 'SEARCH') {
    switch (upperStatus) {
      case 'SEARCH_SUCCESS':
        return STATUS_COLORS.SEARCH_SUCCESS; // Green
      case 'CLAIM_ID_NOT_FOUND':
        return STATUS_COLORS.CLAIM_ID_NOT_FOUND; // Red
      case 'CLAIM_DETAILS_NOT_FOUND':
        return STATUS_COLORS.CLAIM_DETAILS_NOT_FOUND; // Orange
      case 'EDIT_CODE_NOT_IN_SCOPE':
        return STATUS_COLORS.EDIT_CODE_NOT_IN_SCOPE; // Yellow
      case 'UNKNOWN_ERROR':
        return STATUS_COLORS.UNKNOWN_ERROR; // Red
      default:
        return STATUS_COLORS.DEFAULT;
    }
  }

  return STATUS_COLORS.DEFAULT;
};
